<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item ">
                  {{ $t("Clinic Management") }}
                </li>
                <li class="breadcrumb-item">Inventory</li>
                <li class="breadcrumb-item">Summary Stock</li>
                <li class="breadcrumb-item fw-bolder">Detail Item</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">General Item Detail</div>
            <div class="card-body">
              <!-- List group -->
              <div class="list-group list-group-flush my-n3">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Item Category</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Link -->
                      <small class="">
                        {{ details.item_category_name }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Item Generic Name</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Time -->
                      <small class="">
                        {{ details.item_generic_name }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Item Brand Name</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Time -->
                      <small class="">
                        {{ details.item_product_name }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Manufacture</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Text -->
                      <small class="">
                        {{ details.item_manufacturer }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Unit</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Text -->
                      <small class="">
                        {{ details.uom_label }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">Clinic Item Detail</div>
            <div class="card-body">
              <!-- List group -->
              <div class="list-group list-group-flush my-n3">
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">SAP Material Number</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Time -->
                      <small class="">
                        {{
                          "clinic_detail" in details
                            ? details.clinic_detail.sap_material_number
                            : ""
                        }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Minimum Stock Type</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Time -->
                      <small class="">
                        {{
                          "clinic_detail" in details
                            ? details.clinic_detail.min_stock_type
                            : ""
                        }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
                <div class="list-group-item">
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">Minimun Stock</h5>
                    </div>
                    <div class="col-auto">
                      <!-- Text -->
                      <small class="">
                        {{
                          "clinic_detail" in details
                            ? details.clinic_detail.item_min_stock
                            : ""
                        }}
                        {{
                          "clinic_detail" in details
                            ? details.clinic_detail.item_min_stock_uom_name
                            : ""
                        }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-header">Clinic Price Index</div>
            <div class="card-body">
              <!-- List group -->
              <div class="list-group list-group-flush my-n3">
                <div
                  class="list-group-item"
                  v-for="(price, index) in details.prices"
                  :key="index"
                >
                  <div class="row align-items-center">
                    <div class="col">
                      <!-- Title -->
                      <h5 class="mb-0">
                        Rp. {{ converCurrency(price.item_price) }}/{{
                          price.uom_name
                        }}
                      </h5>
                    </div>
                    <div class="col-auto">
                      <!-- Time -->
                      <small class="">
                        <!-- {{ price }} -->
                        {{ convertDate(price.log_time) }}
                      </small>
                    </div>
                  </div>
                  <!-- / .row -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "detailDrugs",
  components: {
    // Drugs
  },
  computed: {
    details() {
      return this.$store.getters["reporting/detailDrugsData"]
        ? this.$store.getters["reporting/detailDrugsData"]
        : [];
    },
  },
  data() {
    return {
      // direct: "Drugs",
    };
  },
  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      const item_id = this.$router.currentRoute.query.item_id;
      this.$store.dispatch("reporting/detailDrugs", {
        id: item_id,
      });
    },

    converCurrency(d) {
      return d.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    },

    convertDate(d) {
      if (d) {
        return moment(d).format("D MMM YYYY");
      }
      return d;
    },
  },
};
</script>