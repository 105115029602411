<template>
  <!-- HEADER -->
  <div class="header">
    <div class="container-fluid">
      <!-- Body -->
      <div class="mt-4">
        <!-- Title -->
        <div class="card">
          <div class="card-body">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item fw-bolder">
                  {{ $t("Role Management") }}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <!-- Filter -->
      <!-- end of filter -->

      <!-- Table -->
      <div class="card mt-5">
        <div class="card-header">
          <!-- Title -->
          <h4 class="card-header-title">{{ $t("Role Management") }}</h4>
          <!-- Button -->
          <b-button variant="primary" @click="addRoleUser">
            <span class="fe fe-file-plus"></span>
            {{ $t("Add") }}
          </b-button>
          <b-button v-b-toggle.collapse-1 variant="primary" style="margin-left: 7px">
            <span class="fe fe-sliders"></span>
          </b-button>
        </div>
        <b-collapse id="collapse-1">
          <form @submit.prevent="sorting()">
            <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">{{
                    $t("Name")
                  }}</label>
                  <input type="text" class="form-control" v-model="body.fullname" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">Company</label>
                  <v-select v-model="body.company_id" :reduce="(e) => e.id" :options="company" label="company_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Role</label>
                  <v-select v-model="body.roles_id" :options="roleOption" label="roles_name" :reduce="(e) => e.id" />
                </div>
              </div>
            </div>
            <div class="row mb-3" style="margin-right: 15px">
              <div class="col"></div>
              <div class="col-auto">
                <!-- Button -->
                <button type="submit" class="btn btn-primary lift">
                  {{ $t("Filter") }}
                </button>
              </div>
            </div>
            <!-- / .row -->
          </form>
        </b-collapse>
        <vue-good-table :line-numbers="true" :columns="columns" :total-rows="rolemanagement.totalItems"
          :rows="listRoleManagement" :sort-options="{
            enabled: true
          }" ref="my-table" :pagination-options="{
            enabled: true,
            mode: 'pages',
            position: 'bottom',
            perPage: 10,
            perPageDropdown: [10, 50, 100],
            dropdownAllowAll: false,
            setCurrentPage: currentPage,
            nextLabel: 'next',
            prevLabel: 'prev',
            rowsPerPageLabel: 'Rows per page',
            ofLabel: 'of',
            pageLabel: 'page',
            allLabel: 'All'
          }" style-class="vgt-table border-0 table" @on-page-change="onPageChange" @on-per-page-change="onPageChange">

          <!-- Loading -->
          <div slot="emptystate" class="text-center font-size-14">
            No data available
          </div>
          <!-- Custom Column -->
          <template slot="table-row" slot-scope="props">
            <template v-if="props.column.field == 'action'">
              <b-button class="no-wrap btn-pill mr-2" variant="warning" size="sm" @click="updateData(props.row)">
                <span class="fe fe-edit"></span>
              </b-button>
              <b-button class="no-wrap btn-pill" variant="danger" size="sm"
                @click="deleteData(props.row.user_id, props.row.nik)">
                <span class="fe fe-trash"></span>
              </b-button>
            </template>
          </template>
        </vue-good-table>
      </div>

      <!-- Modal -->
      <b-modal id="modal-1" hide-footer centered :title="$t('Role Management Form')">
        <form @submit.prevent="saveAdd()" v-if="isAdd">
          <div class="form-group">
            <label>{{ $t("Role ") }}<span style="color: red">*</span></label>
            <v-select v-model="bodyAdd.roles_id" :options="roleOption" label="roles_name" :reduce="(e) => e.id"
              v-validate="'required'" name="Role" @input="roleChange(bodyAdd.roles_id)" />
            <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
          <div class="form-group">
            <label>{{ $t("User ") }}<span style="color: red">*</span></label>
            <v-select v-model="bodyAdd.nik" :options="userOption" label="fullname" :reduce="(e) => e.nik"
              v-validate="'required'" name="User" @keyup.native="userSearch($event)"
              @input="userChange(bodyAdd.roles_id, bodyAdd.nik)" />
            <!-- @keydown.native="searchUser()" -->
            <span class="text-sm text-danger" v-show="errors.has('User')">{{
              errors.first("User")
            }}</span>
          </div>
          <div class="form-group" v-if="bodyAdd.roles_id === 3">
            <label>{{ $t("Clinic / Lab") }}</label>
            <v-select v-model="bodyAdd.healthcare_facility_id" :options="clinicOption" label="healthcare_facility_name"
              :reduce="(e) => e.healthcare_facility_id" name="Healthcare Facility" />
            <span class="text-sm text-danger"
              v-show="errors.has('Healthcare Facility')">{{ errors.first("Healthcare Facility") }}</span>
          </div>
          <div class="form-group">
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
        <!-- Update -->
        <form @submit.prevent="saveUpdate()" v-else>
          <div class="form-group">
            <label>{{ $t("User ") }}<span style="color: red">*</span></label>
            <p>{{ bodyUpdate.fullname }} - {{ bodyUpdate.nik }}</p>
            <!-- <v-select v-model="bodyUpdate.nik" disabled :options="userOption" label="fullname" :reduce="(e) => e.nik"
              v-validate="'required'" name="User" @keyup.native="userSearch($event)"
              @input="userChange(bodyUpdate.roles_id, bodyUpdate.nik)" />
            <span class="text-sm text-danger" v-show="errors.has('User')">{{
              errors.first("User")
            }}</span> -->
          </div>
          <div class="form-group">
            <label>{{ $t("Role ") }}<span style="color: red">*</span></label>
            <v-select v-model="bodyUpdate.roles_id" :options="roleOption" label="roles_name" :reduce="(e) => e.id"
              v-validate="'required'" name="Role" @input="roleChange(bodyUpdate.roles_id)" :clearable="false" />
            <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
          </div>
          <div class="form-group" v-if="bodyUpdate.roles_id === 3">
            <label>{{ $t("Clinic / Lab") }}</label>
            <v-select v-model="bodyUpdate.healthcare_facility_id" :options="clinicOption"
              label="healthcare_facility_name" :reduce="(e) => e.healthcare_facility_id" name="Healthcare Facility" />
            <span class="text-sm text-danger"
              v-show="errors.has('Healthcare Facility')">{{ errors.first("Healthcare Facility") }}</span>
          </div>
          <div class="form-group">
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit"
              v-if="isLoading == false">
              {{ $t("Save") }}
            </button>
            <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
              <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        </form>
      </b-modal>
      <b-modal id="searchUserModal" hide-footer centered size="xl" @hide="onHide" :title="$t('Role Management Form')">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label>Name</label>
              <input v-model="bodySearching.fullname" type="text" class="form-control">
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label>NIK</label>
              <input v-model="bodySearching.nik" type="text" class="form-control">
            </div>
          </div>
        </div>
        <b-button block variant="primary" class="mb-5" @click="searchUserAll">Search User</b-button>
        <b-skeleton-table v-if="loadSearchUser == true" :rows="1" :columns="5"
          :table-props="{ bordered: true, striped: true }"></b-skeleton-table>
        <div class="table-responsive" v-if="loadSearchUser == false">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th scope="col">Company</th>
                <th scope="col">NIK</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody v-if="resultUser.length">
              <tr v-for="(item, index) in resultUser" :key="index">
                <th scope="row">{{ index+1 }}</th>
                <td>{{ item.fullname }}</td>
                <td>{{ item.company }}</td>
                <td>{{ item.nik }}</td>
                <td>
                  <b-button variant="primary" size="sm" @click="selectedUsers(item)">Select</b-button>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5" class="text-center">No Data Found</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-modal>
      <b-modal id="selectedModal" hide-footer centered size="lg" @hide="onHide" :title="$t('Role Management Form')">
        <div class="row" v-if="selectedUserRole">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Name</label>
              <input type="text" class="form-control" v-model="selectedUserRole.fullname" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">NIK</label>
              <input type="text" class="form-control" v-model="selectedUserRole.nik" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">No KTP</label>
              <input type="text" class="form-control" v-model="selectedUserRole.no_ktp" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Company</label>
              <input type="text" class="form-control" v-model="selectedUserRole.company" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Department</label>
              <input type="text" class="form-control" v-model="selectedUserRole.department" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Position</label>
              <input type="text" class="form-control" v-model="selectedUserRole.position" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Date of Birth</label>
              <input type="text" class="form-control" v-model="selectedUserRole.date_of_birth" disabled>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Gender</label>
              <input type="text" class="form-control" v-model="selectedUserRole.gender" disabled>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label>{{ $t("Role ") }}<span style="color: red">*</span></label>
              <v-select v-model="bodyAdd.roles_id" :options="roleOption" label="roles_name" :reduce="(e) => e.id"
                v-validate="'required'" name="Role" @input="roleChange(bodyAdd.roles_id)" />
              <span class="text-sm text-danger" v-show="errors.has('Role')">{{
              errors.first("Role")
            }}</span>
            </div>
          </div>
          <div class="col">
            <div class="form-group" v-if="bodyAdd.roles_id === 3">
              <label>{{ $t("Clinic / Lab") }}</label>
              <v-select v-model="bodyAdd.healthcare_facility_id" :options="clinicOption"
                label="healthcare_facility_name" :reduce="(e) => e.healthcare_facility_id" name="Healthcare Facility" />
              <span class="text-sm text-danger"
                v-show="errors.has('Healthcare Facility')">{{ errors.first("Healthcare Facility") }}</span>
            </div>
          </div>
        </div>
        <div class="form-group">
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="submit" @click="saveAdd()"
            v-if="isLoading == false">
            {{ $t("Save") }}
          </button>
          <button style="margin-left: auto; float: right" class="mt-4 btn btn-primary" type="button" disabled v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Loading...
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
  export default {
    name: "defaultCMS",
    computed: {
      rolemanagement() {
        return this.$store.getters["roleManagement/roleManagement"] ?
          this.$store.getters["roleManagement/roleManagement"] : [];
      },
      healthcare_facility() {
        return this.$store.getters["roleManagement/datahealthcareFacility"] ?
          this.$store.getters["roleManagement/datahealthcareFacility"] : [];
      },
      company() {
        return this.$store.getters["roleManagement/dataCompany"] ?
          this.$store.getters["roleManagement/dataCompany"] : [];
      },
      position() {
        return this.$store.getters["roleManagement/dataStaffRoles"] ?
          this.$store.getters["roleManagement/dataStaffRoles"] : [];
      },
      role() {
        return this.$store.getters["roleManagement/dataRoles"] ?
          this.$store.getters["roleManagement/dataRoles"] : [];
      },
      users() {
        return this.$store.getters["roleManagement/dataUser"] ?
          this.$store.getters["roleManagement/dataUser"] : [];
      }
    },
    data() {
      return {
        body: {
          page: 1,
          limit: 10,
          company_id: "",
          roles_id: "",
          fullname: ""
        },
        bodys: {
          page: 1,
          limit: 10,
          order: ""
        },
        bodyAdd: {
          healthcare_facility_id: "",
          company_id: "",
          roles_id: "",
          nik: ""
        },
        bodyUpdate: {
          healthcare_facility_id: "",
          company_id: "",
          position_id: "",
          roles_id: "",
          nik: "",
          nik_old: ""
        },
        isAdd: true,
        isLoading: false,
        columns: [{
            label: "NAME",
            field: "fullname",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "COMPANY",
            field: "company",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "ROLE",
            field: "roles_name",
            thClass: "table-header w-auto text-muted font-weight-bold",
            tdClass: "color-secondary align-middle"
          },
          {
            label: "ACTION",
            field: "action",
            thClass: "table-header no-wrap text-center text-muted font-weight-bold",
            tdClass: "no-wrap color-secondary text-center align-middle",
            sortable: false
          }
        ],
        bodySearching: {
          fullname: "",
          nik: ""
        },
        resultUser: [],
        selectBlock: [],
        loadSearchUser: false,
        selectedUserRole: [],
        roleOption: [],
        userOption: [],
        clinicOption: [],
        loginRole: null,
        search_timeout: null,
        listRoleManagement: [],
        searchName: "",
        toSearch: null,
        statusFilter: false,
        currentPage: 1
      };
    },
    mounted() {
      // if (this.rolemanagement && this.rolemanagement.length < 1) {
        let user_role = localStorage.getItem("user_roles");
        // const roleId = userLogin ? Number(userLogin) : 0;
        let companys = localStorage.getItem("company");
        if (JSON.parse(user_role).findIndex((e) => e.roles_id == 1 || e.roles_id == 3) > -1) {
          this.body.company_id = "";
        } else {
          const companyId = companys ? Number(companys) : 0;
          this.body.company_id = companyId
        }
      this.getRoleManagement();
      this.getHealthcareFacility();
      this.getCompany();
      this.getStaffRole();
      this.getRole();
      // this.getUsers();
      // }
    },
    methods: {
      roleChange(id) {
        // if (this.isAdd) {
        //   this.bodyAdd.nik = "";
        // } else {
        //   this.bodyUpdate.nik = "";
        // }
        this.userOption = [];
        if (this.isAdd) {
          this.bodyAdd.healthcare_facility_id = "";
        } else {
          this.bodyUpdate.healthcare_facility_id = "";
        }
        this.clinicOption = [];
        const companyId = localStorage.getItem("company");
        if (id === 1) {
          const findUser = this.users.filter((el) => el.company_id === "1");
          this.userOption = findUser;
        } else if (id === 2) {
          if (this.loginRole === 1) {
            this.userOption = this.users;
          } else if (this.loginRole === 2) {
            const findUser = this.users.filter(
              (el) => el.company_id === companyId
            );
            this.userOption = findUser;
          } else {
            this.userOption = this.users;
          }
        } else if (id === 3) {
          if (this.loginRole === 2) {
            const findUser = this.users.filter(
              (el) => el.company_id === companyId
            );
            this.userOption = findUser;
          } else {
            const findUser = this.users.filter((el) => el.company_id === null);
            this.userOption = findUser;
          }
          const findClinic = this.healthcare_facility.filter(
            (el) =>
            el.medical_facility_name === "Clinic External" ||
            el.medical_facility_name === "Lab"
          );
          this.clinicOption = findClinic;
        } else if (id === 4 || id === 5) {
          if (this.loginRole === 2) {
            const findUser = this.users.filter(
              (el) => el.company_id === companyId
            );
            this.userOption = findUser;
          } else {
            this.userOption = this.users;
          }
        } else if (id === 6) {
          if (this.loginRole === 2) {
            const findUser = this.users.filter(
              (el) => el.company_id === companyId
            );
            this.userOption = findUser;
          } else {
            this.userOption = this.users;
          }
        }
      },
      userChange(idRole, nik) {
        const userSelected = this.userOption.find((el) => el.nik === nik);
        if (nik) {
          if (userSelected.nik && userSelected.nik !== "") {
            if (
              idRole === 1 ||
              idRole === 2 ||
              idRole === 4 ||
              idRole === 5 ||
              idRole === 6
            ) {
              if (this.isAdd) {
                this.bodyAdd.healthcare_facility_id = "";
              } else {
                this.bodyUpdate.healthcare_facility_id = "";
              }
              this.clinicOption = [];
              const findUser = this.users.find((el) => el.nik === nik);
              const findClinic = this.healthcare_facility.filter(
                (el) =>
                el.medical_facility_name === "Clinic Internal" &&
                el.company_id === findUser.company_id
              );
              if (this.isAdd) {
                this.bodyAdd.healthcare_facility_id =
                  findClinic.length > 0 ?
                  findClinic[0].healthcare_facility_id :
                  null;
                this.bodyAdd.company_id = findUser.company_id;
              } else {
                this.bodyUpdate.healthcare_facility_id =
                  findClinic.length > 0 ?
                  findClinic[0].healthcare_facility_id :
                  null;
                this.bodyUpdate.company_id = findUser.company_id;
              }
            }
          } else {
            this.$swal("Error", "NIK is not found in iSafe database", "error");
          }
        }
      },
      userSearch(e) {
        clearTimeout(this.toSearch);
        this.toSearch = setTimeout(async () => {
          this.searchName = await e.target.value;
          await this.getUsers();
          if (this.isAdd) {
            await this.roleChange(this.bodyAdd.roles_id);
          } else {
            await this.roleChange(this.bodyUpdate.roles_id);
          }
        }, 350);
      },
      dataRole(props) {
        const data = props.user_roles;
        let data_role = data.map((e) => {
          return e.roles_name;
        });
        return data_role.join(", ");
      },
      saveAdd() {
        this.$validator.validateAll().then(async (res) => {
          if (!res) return false;
          try {
            this.isLoading = true;
            this.$store
              .dispatch("roleManagement/addRoleManagement", this.bodyAdd)
              .then((resp) => {
                this.$swal({
                  title: this.$t("Saved"),
                  text: this.$t("Your input has been saved"),
                  icon: "success",
                  confirmButtonText: this.$t("OK")
                }).then((resp) => {
                  this.isLoading = false;
                  this.currentPage = 1;
                  this.$refs["my-table"].reset();
                  this.bodyAdd.healthcare_facility_id = "";
                  this.bodyAdd.roles_id = "";
                  this.bodyAdd.nik = "";
                  this.getRoleManagement();
                  this.$bvModal.hide("modal-1");
                  this.$bvModal.hide("searchUserModal");
                  this.$bvModal.hide("selectedModal");
                });
              }).catch((err) => {
                this.$swal.fire("Error!", `${err.response.data.error}`, "error");
                this.isLoading = false
              });
          } catch (resp) {
            this.$swal("Error", this.$t("Error while add data"), "error");
          }
        });
      },

      async searchUser(search) {
        clearTimeout(this.search_timeout);
        this.search_timeout = setTimeout(async () => {
          await this.$store.dispatch("roleManagement/getUser", {
            fullname: search
          });
          // loading(false);
        }, 500);
      },

      // api hit
      async getRoleManagement() {
        this.$store
          .dispatch("roleManagement/getDataRoleManagement", this.body)
          .then(() => {
            console.log(this.rolemanagement.data);
            // if (roleId !== 1) {
            //   const filter = this.rolemanagement.data.rows.filter(
            //     (el) => el.company_id === companys
            //   );
            //   this.listRoleManagement = filter;
            // } else {
            this.listRoleManagement = this.rolemanagement.data.rows;
            // }
          });
      },
      getHealthcareFacility() {
        this.$store.dispatch("roleManagement/getHealthCare");
      },
      getCompany() {
        this.$store.dispatch("roleManagement/getCompany");
      },
      getStaffRole() {
        this.$store.dispatch("roleManagement/getStaffRoles");
      },
      getRole() {
        this.$store.dispatch("roleManagement/getRoles").then(() => {
          let roleLogin = localStorage.getItem("roles_id");
          this.loginRole =
            roleLogin && roleLogin !== "null" ? Number(roleLogin) : null;

          if (this.loginRole === 2) {
            const findRole = this.role.filter((el) => el.id !== 1 && el.id !== 3);
            this.roleOption = findRole;
          } else {
            this.roleOption = this.role;
          }
        });
      },
      getUsers() {
        // let roleLogin = localStorage.getItem('roles_id');
        this.$store.dispatch("roleManagement/getUser", {
          fullname: this.searchName
        });
      },

      // filter
      filterPage() {
        this.getRoleManagement();
      },

      sorting() {
        if (this.body.fullname || this.body.company_id || this.body.roles_id) {
          this.statusFilter = true
        } else {
          this.statusFilter = false
        }
        this.getRoleManagement();
      },

      addRoleUser() {
        this.$bvModal.show("searchUserModal")
      },

      onHide() {
        this.resultUser = []
        this.bodySearching = {
          fullname: "",
          nik: ""
        }
      },

      searchUserAll() {
        if (this.bodySearching.fullname || this.bodySearching.nik) {
          this.loadSearchUser = true
          this.$store.dispatch("roleManagement/getUser", this.bodySearching).then(() => {
            this.loadSearchUser = false
            let data = this.$store.getters["roleManagement/dataUser"]
            this.resultUser = data
          });
        }

      },

      selectedUsers(item) {
        console.log(item);
        this.selectedUserRole = item
        this.bodyAdd.nik = item.nik
        this.bodyAdd.company_id = item.company_id

        this.clinicOption = [];
        const findUser = this.users.find((el) => el.nik === item.nik);
        const findClinic = this.healthcare_facility.filter(
          (el) =>
          el.medical_facility_name === "Clinic Internal" &&
          el.company_id === findUser.company_id
        );
        if (this.isAdd) {
          this.bodyAdd.healthcare_facility_id =
            findClinic.length > 0 ?
            findClinic[0].healthcare_facility_id :
            null;
          this.bodyAdd.company_id = findUser.company_id;
        } else {
          this.bodyUpdate.healthcare_facility_id =
            findClinic.length > 0 ?
            findClinic[0].healthcare_facility_id :
            null;
          this.bodyUpdate.company_id = findUser.company_id;
        }
        setTimeout(() => {
          this.$bvModal.show("selectedModal")
          console.log(this.selectedUserRole);
        }, 500);
      },

      // Pagination
      onPageChange(payload) {
        let body = {}
        console.log(payload);
        if (this.statusFilter == true) {
          this.body.limit = payload.currentPerPage,
            this.body.page = payload.currentPage
          body = this.body
        }
        if (this.statusFilter == false) {
          let isBody = {
            limit: payload.currentPerPage,
            page: payload.currentPage
          }
          body = isBody
        }
        let user_role = localStorage.getItem("user_roles");
        // const roleId = userLogin ? Number(userLogin) : 0;
        let companys = localStorage.getItem("company");
        // if (JSON.parse(user_role).findIndex((e) => e.roles_id == 1 || e.roles_id == 3) > -1) {
        //   const companyId = companys ? Number(companys) : 0;
        //   this.body.company_id = companyId;
        // }

        this.$store
          .dispatch("roleManagement/getDataRoleManagement", body)
          .then(() => {
            console.log(body);
            // if (roleId !== 1) {
            //   const filter = this.rolemanagement.data.rows.filter(
            //     (el) => el.company_id === companys
            //   );
            //   this.listRoleManagement = filter;
            // } else {
            this.listRoleManagement = this.rolemanagement.data.rows;
            // }
          });
      },

      updateData(data) {
        this.isAdd = false;
        this.roleChange(data.roles_id);
        this.bodyUpdate.fullname = data.fullname
        this.bodyUpdate.nik = data.nik
        this.bodyUpdate.healthcare_facility_id = data.healthcare_facility_id;
        this.bodyUpdate.company_id = data.company_id;
        this.bodyUpdate.position_id = data.id_position;
        this.bodyUpdate.roles_id = data.roles_id;
        this.bodyUpdate.nik = data.nik;
        this.bodyUpdate.nik_old = data.nik;

        setTimeout(() => {
          this.$bvModal.show("modal-1");
        }, 250);
      },
      saveUpdate() {
        this.$store
          .dispatch("roleManagement/updateRoleManagement", this.bodyUpdate)
          .then((resp) => {
            this.$swal("Saved", "Your data has been edit", "success").then(
              (resp) => {
                this.currentPage = 1;
                this.$refs["my-table"].reset();
                this.getUsers();
                this.getRoleManagement();
                this.$bvModal.hide("modal-1");
              }
            );
          })
          .catch((resp) => {
            this.$swal("Error", "Error while edit data", "error");
          });
      },
      deleteData(user_id, nik) {
        this.$swal({
          title: this.$t("Are you sure?"),
          text: this.$t("You won't be able to revert this!"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#00D97E",
          cancelButtonColor: "#E63757",
          confirmButtonText: this.$t("Yes, delete it!"),
          cancelButtonText: this.$t("Cancel")
        }).then((result) => {
          if (result.isConfirmed) {
            if (user_id) {
              let data = {
                user_id: user_id
              }
              this.$store
                .dispatch("roleManagement/deleteRoleManagement", data)
                .then((resp) => {
                  this.getRoleManagement();
                  this.$swal(
                    "Deleted!",
                    this.$t("Your data has been deleted"),
                    "success"
                  );
                })
                .catch((resp) => {
                  this.$swal("Error", this.$t("Error while delete data"), "error");
                });
            } else {
              let dat = {
                nik: nik
              }
              this.$store
                .dispatch("roleManagement/deleteRoleManagement", dat)
                .then((resp) => {
                  this.getRoleManagement();
                  this.$swal(
                    "Deleted!",
                    this.$t("Your data has been deleted"),
                    "success"
                  );
                })
                .catch((resp) => {
                  this.$swal("Error", this.$t("Error while delete data"), "error");
                });
            }
          }
        });
      }
    }
  };
</script>