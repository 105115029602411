var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card p-0"},[_vm._m(0),(_vm.load === false)?_c('div',[_c('div',{staticClass:"list-group list-group-flush"},[_c('div',{staticClass:"list-group-item p-4"},[_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col"},[_vm._m(2),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.nik))])])]),_vm._m(3),_c('div',{staticClass:"col"},[_vm._m(4),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.patient_name))])])]),_vm._m(5),_c('div',{staticClass:"col"},[_vm._m(6),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.company))])])]),_vm._m(7),_c('div',{staticClass:"col"},[_vm._m(8),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.department))])])]),_vm._m(9),_c('div',{staticClass:"col"},[_vm._m(10),_c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v(_vm._s(this.history.position))])])])])])])]):_c('div',{staticClass:"card-body text-center"},[_vm._m(11)])]),_vm._l((this.dataRec),function(item,index){return _c('div',{key:index,staticClass:"card"},[_c('div',{},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(item.group_name))])]),(_vm.load === false)?_c('div',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm"},[_vm._m(12,true),_c('tbody',_vm._l((item.record),function(e,i){return _c('tr',{key:i},[_c('td',[_vm._v(_vm._s(e.check_title))]),_c('td',[_vm._v(_vm._s(e.check_value))]),_c('td',[_vm._v(_vm._s(e.item_name))]),_c('td',[_vm._v(_vm._s(e.item_unit))])])}),0)])])]):_c('div',{staticClass:"card-body text-center"},[_vm._m(13,true)])])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v("Patient Info")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-credit-card"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("NIK")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-user"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Name")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-home"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Company")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-layers"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Departement")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"avatar avatar-sm"},[_c('div',{staticClass:"avatar-title fs-lg bg-primary-soft rounded-circle text-primary"},[_c('i',{staticClass:"fe fe-award"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"small mt-2"},[_c('strong',[_vm._v("Position")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticClass:"text-center"},[_vm._v("Check Title")]),_c('th',{staticClass:"text-center"},[_vm._v("Check Value")]),_c('th',{staticClass:"text-center"},[_vm._v("Item")]),_c('th',{staticClass:"text-center"},[_vm._v("Item Unit")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }