<template>
  <!-- HEADER -->
  <div class="row align-items-center justify-content-between">
    <div :class="`cart ${load === true ? '' : 'd-none'}`">
      <div class="card-body text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div :class="`cart ${load === true ? 'd-none' : ''}`">
      <div class="row mt-4" style="margin-left: 15px; margin-right: 15px">
        <div class="col-2">
          <div class="form-group">
            <label for="inputPassword">{{ $t("Date") }}</label>
            <input
              @change="sorting(load)"
              type="month"
              v-model="bodyDataDaily.date_input"
              class="form-control"
              placeholder="Pilih Tanggal"
              v-validate="'required'"
              name="Tanggal"
              :max="MonthNow()" />
          </div>
        </div>
        <div class="col-4" v-if="filterIsAdmin()">
          <div class="form-group">
            <label>{{ $t("Company") }}</label>
            <v-select
              :options="selectCompanyOption()"
              v-model="bodyDataDaily.company_id"
              label="company_name"
              :reduce="(e) => e.id"
              @input="changeCompany()" />
          </div>
        </div>
        <div class="col">
          <div class="form-group mt-4 d-flex float-end">
            <b-button variant="primary" @click="reloadPage()" class="mr-3">
              <span class="fe fe-rotate-cw"></span>
            </b-button>
            <b-button variant="primary" @click="download()" class="mr-3">
              <span class="fe fe-download"></span>
              Export
            </b-button>
            <b-button v-b-modal.modal-import variant="primary" class="mr-3">
              <span class="fe fe-upload"></span>
              {{ $t("Import") }}
            </b-button>
            <!-- <b-button v-b-modal.modal-1 variant="primary" @click="isAdd = true">
              <span class="fe fe-edit-2"></span>
              {{ $t("Edit Data") }}
            </b-button> -->
          </div>
        </div>
      </div>
      <hr />
      <!-- <div class="x -->
      <b-tabs fill>
        <b-tab title="OH Indicator" @click="type1">
          <div class="cart" v-if="ohDaily.length === 0">
            <div class="card-body text-center">
              <b-alert variant="danger" show
                >This company hasn't filled out OH data in
                {{ dateDataNol }}</b-alert
              >
            </div>
          </div>
          <div class="tableFixHead" style="overflow: auto" v-else>
            <div :class="`cart ${load === true ? '' : 'd-none'}`">
              <div class="card-body text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <table
              class="table table-sm table-nowrap table-bordered oh-table"
              v-if="!load">
              <thead>
                <tr>
                  <td scope="col" rowspan="2" class="sticky-position">
                    Occupational Health Indicators
                  </td>
                  <th scope="col" rowspan="2" style="text-align: center">
                    {{ lastmonth(lastMothbodyDataDaily.date_input) }}
                  </th>
                  <th class="tableFix" colspan="31" style="text-align: center">
                    {{ dates(bodyDataDaily.date_input) }}
                  </th>
                  <th rowspan="2" style="text-align: center">Total</th>
                </tr>
                <th
                  v-for="(i, index) in lastDate(bodyDataDaily.date_input)"
                  :key="index"
                  scope="col"
                  class="text-center">
                  {{ i }}
                </th>
              </thead>
              <tbody>
                <tr v-for="(row, index_row) in ohDaily" :key="row.id">
                  <th scope="row" style="text-align: left">
                    {{ row.oh_label }}
                    <span
                      v-if="row.description != null"
                      :title="row.description"
                      class="fe fe-alert-circle"></span>
                  </th>
                  <td
                    style="text-align: center"
                    v-if="bodyDataDaily.oh_type == 'Data'">
                    {{
                      convertNumber(
                        findOhByIdAndType(row.oh_id, row.oh_type)
                      ) == "NaN"
                        ? "0"
                        : convertNumber(
                            findOhByIdAndType(row.oh_id, row.oh_type)
                          )
                    }}
                  </td>
                  <!-- contentEditableCondition(row, index_subcol) -->
                  <td
                    v-for="(item, index_subcol) in lastday"
                    class="my-input"
                    :key="index_subcol + 'A'"
                    v-show="contentEditableVisibility()"
                    :id="index_row + '-' + index_subcol"
                    :contenteditable="
                      contentEditableCondition(row, index_subcol)
                    "
                    :ref="index_row + '-' + index_subcol"
                    @blur="
                      Updates($event, index_row, index_subcol, item, row.values)
                    "
                    @keydown="
                      Update($event, index_row, index_subcol, item, row.values)
                    ">
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <td
                    v-for="(item, index_subcol) in lastday"
                    class="my-input"
                    :key="index_subcol + 'B'"
                    v-show="contentEditableVisibility(2)"
                    :id="index_row + '-' + index_subcol"
                    :ref="index_row + '-' + index_subcol"
                    :contenteditable="
                      contentEditableCondition(row, index_subcol, 2)
                    "
                    @blur="
                      Updates($event, index_row, index_subcol, item, row.values)
                    "
                    @keydown="
                      Update($event, index_row, index_subcol, item, row.values)
                    ">
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <th
                    colspan="6"
                    style="text-align: center; color: black"
                    v-if="bodyDataDaily.oh_type == 'Data'">
                    {{
                      convertNumber(findTotalMonthData(row.oh_id, row.oh_type))
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>

        <b-tab :title="$t('OH Leading Indicator')" @click="type2">
          <div class="cart" v-if="ohDaily.length === 0">
            <div class="card-body text-center">
              <b-alert variant="danger" show
                >This company hasn't filled out OH data in
                {{ dateDataNol }}</b-alert
              >
            </div>
          </div>
          <div class="tableFixHead" style="overflow: auto" v-else>
            <div :class="`cart ${load === true ? '' : 'd-none'}`">
              <div class="card-body text-center">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
            <table
              :class="`table table-sm table-nowrap table-bordered tableFixHead oh-table ${
                load === true ? 'd-none' : ''
              }`">
              <thead>
                <tr>
                  <th scope="colss" rowspan="2">
                    Occupational Health Indicators
                  </th>
                  <th scope="col" rowspan="2" style="text-align: center">
                    {{ lastmonth(lastMothbodyActivityDaily.date_input) }}
                  </th>
                  <th colspan="31" style="text-align: center">
                    {{ dates(bodyDataDaily.date_input) }}
                  </th>
                  <th rowspan="2" style="text-align: center">Total</th>
                </tr>
                <tr>
                  <th
                    v-for="(i, index) in lastDate(bodyDataDaily.date_input)"
                    :key="index"
                    scope="col"
                    class="text-center">
                    {{ i }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index_rows) in ohDaily" :key="row.id">
                  <th scope="row" style="text-align: left">
                    {{ row.oh_label }}
                    <span
                      v-if="row.description != null"
                      :title="row.description"
                      class="fe fe-alert-circle"></span>
                  </th>
                  <td
                    style="text-align: center"
                    v-if="bodyDataDaily.oh_type == 'Activity'">
                    {{
                      convertNumber(
                        findOhByIdAndTypeActivity(row.oh_id, row.oh_type)
                      ) == "NaN"
                        ? "0"
                        : convertNumber(
                            findOhByIdAndTypeActivity(row.oh_id, row.oh_type)
                          )
                    }}
                  </td>
                  <td
                    class="my-input"
                    v-for="(item, index_subcols) in lastday"
                    :key="index_subcols + 'C'"
                    :id="index_rows + '/' + index_subcols"
                    :ref="index_rows + '/' + index_subcols"
                    v-show="contentEditableVisibility()"
                    :contenteditable="
                      contentEditableCondition2(row, index_subcols)
                    "
                    @blur="
                      UpdatesActivity(
                        $event,
                        index_rows,
                        index_subcols,
                        item,
                        row.values
                      )
                    "
                    @keydown="
                      UpdateActivity(
                        $event,
                        index_rows,
                        index_subcols,
                        item,
                        row.values
                      )
                    ">
                    <!-- <b-badge variant="primary"> -->
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <td
                    class="my-input"
                    v-for="(item, index_subcols) in lastday"
                    :key="index_subcols + 'D'"
                    :id="index_rows + '/' + index_subcols"
                    :ref="index_rows + '/' + index_subcols"
                    v-show="contentEditableVisibility(2)"
                    :contenteditable="
                      contentEditableCondition2(row, index_subcols, 2)
                    "
                    @blur="
                      UpdatesActivity(
                        $event,
                        index_rows,
                        index_subcols,
                        item,
                        row.values
                      )
                    "
                    @keydown="
                      UpdateActivity(
                        $event,
                        index_rows,
                        index_subcols,
                        item,
                        row.values
                      )
                    ">
                    {{ convertNumber(revealValue(item, row.values)) }}
                  </td>
                  <th
                    colspan="6"
                    style="text-align: center; color: black"
                    v-if="bodyDataDaily.oh_type == 'Activity'">
                    {{
                      convertNumber(findTotalActivity(row.oh_id, row.oh_type))
                    }}
                  </th>
                </tr>
              </tbody>
            </table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <!-- Body -->
    <!-- Modal -->
    <b-modal
      id="modal-1"
      size="xl"
      hide-footer
      :title="$t('Form Input OH Daily')">
      <div v-if="isOnLoading == false">
        <form @submit.prevent="saveAdd()" v-if="isAdd === true">
          <div class="row">
            <div class="form-group col-6">
              <label>{{ $t("Date") }}</label>
              <div class="d-flex">
                <input
                  type="date"
                  @change="getOhDaily()"
                  v-model="addBodyDataDaily.date_input"
                  class="form-control"
                  placeholder="Pilih Tanggal"
                  v-validate="'required'"
                  name="Tanggal"
                  :max="Datenow()" />
                <span
                  class="text-sm text-danger"
                  v-show="errors.has('Tanggal')"
                  >{{ errors.first("Tanggal") }}</span
                >
                <button
                  class="btn btn-primary ml-3"
                  type="button"
                  disabled
                  v-if="loadDate == true">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                  <span class="visually-hidden">Loading...</span>
                </button>
              </div>
            </div>
            <div class="form-group col-6" v-if="statusRecord > 0">
              <label for=""></label>
              <p>This month is already Approved</p>
            </div>
            <div v-else>
              <div
                v-if="
                  loadDate == false && addBodyDataDaily.date_input.length > 0
                ">
                <div class="row">
                  <label for="">
                    <h3>{{ $t("Occupational Health") }}</h3>
                  </label>
                  <template v-if="record.length > 0">
                    <div
                      class="form-group col-6"
                      v-for="(item, index) in record"
                      :key="index">
                      <!-- <div v-for="(items, index) in reqRecord" :key="index"> -->
                      <label>{{ item.oh_label }}</label>
                      <span v-if="item.is_mandatory == true" style="color: red"
                        >* {{ type_data }}</span
                      >
                      <span
                        :title="item.description"
                        class="fe fe-alert-circle"></span>
                      <div>
                        <input
                          v-if="item.is_mandatory == true"
                          :type="
                            item.type_data == 'Integer' ? 'number' : 'text'
                          "
                          :disabled="item.status > 0"
                          v-model="record[index]['value']"
                          class="form-control"
                          :placeholder="record[index]['value']"
                          v-validate="'required'"
                          :name="item.oh_label"
                          min="0" />
                        <input
                          v-else
                          :type="
                            item.type_data == 'Integer' ? 'number' : 'text'
                          "
                          :disabled="item.status > 0"
                          :name="item.oh_label"
                          class="form-control"
                          :placeholder="record[index]['value']"
                          v-validate="'required'"
                          v-model="record[index]['value']"
                          min="0" />
                      </div>
                      <span
                        class="text-sm text-danger"
                        v-show="errors.has(item.oh_label)"
                        >{{ "This Field is Required" }}</span
                      >
                      <!-- </div> -->
                    </div>
                  </template>
                </div>
                <div class="row">
                  <label for="">
                    <h3>{{ $t("Occupational Health Activity") }}</h3>
                  </label>
                  <template v-if="recordAct.length > 0">
                    <div
                      class="form-group col-6"
                      v-for="(item, index) in recordAct"
                      :key="'A' + index">
                      <label>{{ item.oh_label }}</label>
                      <span v-if="item.is_mandatory == true" style="color: red"
                        >*</span
                      >
                      <span
                        :title="item.description"
                        class="fe fe-alert-circle"></span>
                      <div>
                        <input
                          v-if="item.is_mandatory == true"
                          :type="
                            item.type_data == 'Integer' ? 'number' : 'text'
                          "
                          :disabled="item.status > 0 || item.manual_input == 1"
                          v-model="recordAct[index]['value']"
                          class="form-control"
                          :placeholder="recordAct[index]['value']"
                          v-validate="'required'"
                          :name="item.oh_label"
                          min="0" />
                        <input
                          v-else
                          :type="
                            item.type_data == 'Integer' ? 'number' : 'text'
                          "
                          :disabled="item.status > 0 || item.manual_input == 1"
                          :name="item.oh_label"
                          class="form-control"
                          :placeholder="recordAct[index]['value']"
                          v-validate="'required'"
                          v-model="recordAct[index]['value']"
                          min="0" />
                      </div>
                      <span
                        class="text-sm text-danger"
                        v-show="errors.has(item.oh_label)"
                        >{{ "This Field is Required" }}</span
                      >
                    </div>
                  </template>
                </div>
              </div>
              <div>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary"
                  type="submit"
                  v-if="isOnLoading == false"
                  :disabled="statusRecord > 0">
                  {{ $t("Save") }}
                </button>
                <button
                  style="margin-left: auto; float: right"
                  class="mt-4 btn btn-primary"
                  type="button"
                  disabled
                  v-else>
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"></span>
                  Loading...
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div disabled v-if="isOnLoading == true" class="text-center">
        <div class="overlay-layer bg-opacity-5">
          <div
            class="spinner-border text-primary"
            style="width: 6rem; height: 6rem"
            role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-import"
      size=""
      hide-footer
      :title="$t('Form Import Excel')">
      <div>
        <div class="form-group">
          <label>File</label>
          <input type="file" @change="fileUploader" class="form-control ">
        </div>
      </div>
      <div>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="submit"
          @click="importFile()"
          v-if="loadImport == false">
          {{ $t("Save") }}
        </button>
        <button
          style="margin-left: auto; float: right"
          class="mt-4 btn btn-primary"
          type="button"
          disabled
          v-else>
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"></span>
          Loading...
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import axiosIns from "@/api/axios";
import { parse } from "query-string";
import { BIconSortNumericDown } from "bootstrap-vue";

import typeVue from "@/views/hazard/type.vue";
import { MonthPickerInput } from "vue-month-picker";

export default {
  name: "ohManagement",
  computed: {
    ohData() {
      return this.$store.getters["ohData/OhData"]
        ? this.$store.getters["ohData/OhData"]
        : [];
    },
    activity() {
      return this.$store.getters["activity/ActivityData"]
        ? this.$store.getters["activity/ActivityData"]
        : [];
    },
    ohDaily() {
      return this.$store.getters["detailManagement/OHDailyData"]
        ? this.$store.getters["detailManagement/OHDailyData"]
        : [];
    },
    url() {
      return this.$store.getters["summary/excelUrl"]
        ? this.$store.getters["summary/excelUrl"]
        : [];
    },
    company() {
      return this.$store.getters["detailManagement/dataCompany"]
        ? this.$store.getters["detailManagement/dataCompany"]
        : [];
    },
    last_update() {
      return this.$store.getters["detailManagement/lastUpdate"]
        ? this.$store.getters["detailManagement/lastUpdate"]
        : [];
    },
    companySelect() {
      return this.$store.getters["detailManagement/companySelectData"]
        ? this.$store.getters["detailManagement/companySelectData"]
        : "1";
    }
  },
  components: {
    MonthPickerInput
  },
  props: ["id", "is_admin"],
  data() {
    return {
      body: {
        page: 1,
        limit: 100,
        order: "",
        data_flag: "Daily"
      },
      bodys: {
        page: 1,
        limit: 100,
        order: "",
        activity_flag: "Daily"
      },
      bodyDataDaily: {
        oh_type: "Data",
        type_input: "Daily",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY-MM")
      },
      lastMothbodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM")
      },
      totalbodyDataDaily: {
        oh_type: "Data",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY-MM")
      },
      totalbodyActivityDaily: {
        oh_type: "Activity",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment().format("YYYY-MM")
      },
      lastMothbodyActivityDaily: {
        oh_type: "Activity",
        type_input: "Monthly",
        company_id: localStorage.getItem("company"),
        date_input: moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM")
      },
      addBodyDataDaily: {
        type_input: "Daily",
        date_input: "",
        timezone: moment().format("Z")
      },
      excelBody: {
        date_input: moment().format("YYYY-MM"),
        data: "Daily"
      },
      dbl: true,
      company_id: "",
      date_input: "",
      record: [],
      recordAct: [],
      reqRecord: [],
      reqRecordAct: [],
      isAdd: true,
      show: true,
      lastday: "",
      lastMonthData: [],
      lastMonthActivty: [],
      totalMonthData: [],
      totalMonthActivty: [],
      lastMonthAct: [],
      nowMonth: "",
      revalValueAd: [],
      statusRecord: "",
      fileImport: "",
      loadImport: false,
      isOnLoading: false,
      load: true,
      loadDate: false,
      editable: false,
      active: false,
      myinput: "",
      user_id: "",
      user_role: {},
      companyName: "",
      filterCompany: "",
      dateNow: moment().format("YYYY-MM-DD"),
      dateLast: moment().format("YYYY-MM-DD"),
      dayNow: moment().format("D"),
      dateDataNol: moment().format("MMMM-YYYY"),
      testTable: [
        [1, "test 1", "12"],
        [2, "test 2", "23"],
        [3, "test 3", "34"],
        [4, "test 4", "45"]
      ]
    };
  },
  mounted() {
    let historyId = localStorage.getItem("seeHistory");
    this.user_id = historyId;
    this.companyName = localStorage.getItem("company_name");
    const lastday = moment(this.bodyDataDaily.date_input)
      .endOf("month")
      .format("D");
    this.lastday = Number(lastday);
    const company = localStorage.getItem("company");
    const roles = localStorage.getItem("roles_id");
    this.filterCompany = parseInt(roles) == 1 ? 1 : 0;
    const userRole = JSON.parse(localStorage.getItem('user_roles'))
    this.user_role = userRole
    // console.log('aeqwewqdc', this.user_role[]);

    // this.$store.commit("detailManagement/setCompanySelect", this.bodyDataDaily.company_id)
    // if (this.filterCompany === 1) {
    // 	this.bodyDataDaily.company_id = this.companySelect
    // 	this.lastMothbodyDataDaily.company_id = this.companySelect
    // 	this.lastMothbodyActivityDaily.company_id = this.companySelect
    // 	this.totalbodyDataDaily.company_id = this.companySelect
    // 	this.totalbodyActivityDaily.company_id = this.companySelect
    // }

    this.customGetOH();
    // this.getLink();

    this.getLastMonthOhData();
    this.gettotalMonthOhData();
    this.getLastMonthOhDataActivity();
    this.getTotalMonthOhDataActivity();
    this.getOh();
    this.getActivity();
    this.isCompany();
    this.getCompanny();
    this.lastUpdate();
    this.nowMonth = moment().format("MMMM");
  },
  methods: {
    reloadPage() {
      this.customGetOH();
      this.getLastMonthOhData();
      this.gettotalMonthOhData();
      this.getLastMonthOhDataActivity();
      this.getTotalMonthOhDataActivity();
    },
    selectCompanyOption() {
      // return this.company
      const company = this.company;
      const term = [];
      // term.push({
      //   id: 0,
      //   company_name: "BIB + Mitra"
      // });

      company.map((i) => {
        term.push({
          id: i.id,
          company_name: i.company_name
        });
      });
      return term;
    },

    revealValue(day, values) {
      const index = values?.findIndex((e) => e.day === day);
      if (index > -1) {
        let val = values[index]["date"];
        if (moment().format("YYYY-MM-DD") >= val) {
          return values[index]["value"];
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    dateReveal(day, values) {
      const index = values.findIndex((e) => e.day === day);
      if (index > -1) {
        return values[index]["day"];
      } else {
        return "";
      }
    },

    async fileUploader(event) {
        this.fileImport = event.target.files[0];
    },

    importFile() {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#00D97E",
        cancelButtonColor: "#E63757",
        confirmButtonText: "Yes!",
        cancelButtonText: "Cancel",
    }).then((result) => {
        if (result.isConfirmed) {
          this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
            this.loadImport = true;
            const formData = new FormData();
            formData.append("files", this.fileImport);
            this.$store
                .dispatch("detailManagement/importFile", formData)
                .then((resp) => {
                    this.$swal("Saved", "Your input has been saved", "success").then(
                        (resp) => {
                            this.loadImport = false;
                            this.fileImport = ""
                            this.reloadPage();
                            this.$bvModal.hide("modal-import");
                        }
                    );
                }).catch((err) => {
                    this.loadImport = false
                    this.$swal("Error", err.response.data.error, "error");
                });
        } catch (error) {
            this.$swal("Error", "Error while add data", "error");
        }
    });
        }
    });
    },

    setFocus(id0, id1) {
      document
        .getElementById(id0 + "-" + id1)
        .addEventListener("contenteditable", function () {}, false);
    },
    getLastMonthOhData() {
      this.$store
        .dispatch(
          "detailManagement/getDataDailyInput",
          this.lastMothbodyDataDaily
        )
        .then(() => {
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            return {
              oh_type: "Data",
              oh_id: Number(item.oh_id),
              val: item.values
            };
          });
          this.lastMonthData = rec;
        });
    },
    findOhByIdAndType(id, type) {
      const data = this.lastMonthData;
      let obj = data.find((o) => {
        return o.oh_type == type && o.oh_id == id;
      });
      const maping = obj?.val;
      var datas = maping?.map((item) => {
        return item.value;
      });
      var dat = datas?.reduce((total, value) => total + value, 0);
      return Number(dat);
    },

    gettotalMonthOhData() {
      this.$store
        .dispatch("detailManagement/getDataDailyInput", this.totalbodyDataDaily)
        .then(() => {
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            return {
              oh_type: "Data",
              oh_id: Number(item.oh_id),
              values: item.values
            };
          });
          this.totalMonthData = rec;
        });
    },

    filterIsAdmin() {
      let role = this.user_role
      let invent = role.findIndex((e) => e.roles_id == 1 || e.roles_id == 3)
      if (invent > -1) {
        return true
      } else {
        return false
      }
    },

    updatetotalMonthOhData() {
      this.$store
        .dispatch("detailManagement/updateDataDailyInput", this.totalbodyDataDaily)
        .then(() => {
          const datas =
            this.$store.getters["detailManagement/updateOHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            return {
              oh_type: "Data",
              oh_id: Number(item.oh_id),
              values: item.values
            };
          });
          this.totalMonthData = rec;
        });
    },

    findTotalMonthData(id, type) {
      const data = this.totalMonthData;
      // let obj = data.find((o) => {
      // 	return o.oh_type == type && o.oh_id == id;
      // });
      // const maping = obj?.values;
      // let datas = maping?.map((item) => {
      // 	return item.value;
      // })
      // if (!maping) {
      // 	return 0
      // }
      // const thisMonth = moment().utc().format('M')
      // var dat = maping.find((v) => v.month === parseInt(thisMonth) && v.day === 1)
      // return Number(dat.value);
      let obj = data.find((o) => {
        return o.oh_type == type && o.oh_id == id;
      });
      const maping = obj?.values;
      if (!maping) {
        return 0;
      }

      let total = 0;
      let idx = 0;
      const date_added = [];
      while (idx < maping.length) {
        const data = maping[idx];
        const indexDate = date_added.findIndex(
          (e) => e.month === data.month && e.day === data.day
        );
        if (indexDate < 0) {
          total += isNaN(data.value) ? 0 : Number(data.value);
          date_added.push({ month: data.month, day: data.day });
        }
        idx++;
      }
      return total;
    },

    getTotalMonthOhDataActivity() {
      this.$store
        .dispatch(
          "detailManagement/getDataDailyInput",
          this.totalbodyActivityDaily
        )
        .then(() => {
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            return {
              oh_type: "Activity",
              oh_id: Number(item.oh_id),
              value: item.values
            };
          });
          this.totalMonthActivty = rec;
        });
    },
    findTotalActivity(id, type) {
      const data = this.totalMonthActivty;
      let obj = data.find((o) => {
        return o.oh_type == type && o.oh_id == id;
      });
      if (obj) {
        let datas = obj.value.map((item) => {
          return item.value;
        });

        var dat = datas.reduce((total, value) => total + value, 0);

        return dat;
      } else {
        return 0;
      }
    },

    getLastMonthOhDataActivity() {
      this.$store
        .dispatch(
          "detailManagement/getDataDailyInput",
          this.lastMothbodyActivityDaily
        )
        .then(() => {
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            return {
              oh_type: "Activity",
              oh_id: Number(item.oh_id),
              value: item.values[0].value
            };
          });
          this.lastMonthActivty = rec;
        });
    },
    findOhByIdAndTypeActivity(id, type) {
      const data = this.lastMonthActivty;
      let obj = data.find((o) => {
        return o.oh_type == type && o.oh_id == id;
      });
      return obj ? obj.value : 0;
    },

    revealDate(day, values) {
      const index = values.findIndex((e) => e.day === day);
      if (index > -1) {
        return values[index]["date"];
      } else {
        return "";
      }
    },
    reveals(day, values) {
      const index = values.findIndex((e) => e.day === day);
      if (index > -1) {
        return values[index]["status"];
      } else {
        return "";
      }
    },

    changeCompany() {
      const company = this.bodyDataDaily.company_id;
      this.bodyDataDaily.date_input = moment(
        this.bodyDataDaily.date_input
      ).format("YYYY-MM");
      this.lastMothbodyDataDaily.company_id = this.bodyDataDaily.company_id;
      this.lastMothbodyDataDaily.date_input = moment(
        this.bodyDataDaily.date_input
      )
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM");

      this.lastMothbodyActivityDaily.company_id = this.bodyDataDaily.company_id;
      this.lastMothbodyActivityDaily.date_input = moment(
        this.bodyDataDaily.date_input
      )
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM");

      this.totalbodyDataDaily.company_id = this.bodyDataDaily.company_id;
      this.totalbodyDataDaily.date_input = moment(
        this.bodyDataDaily.date_input
      ).format("YYYY-MM");

      this.totalbodyActivityDaily.company_id = this.bodyDataDaily.company_id;
      this.totalbodyActivityDaily.date_input = moment(
        this.bodyDataDaily.date_input
      ).format("YYYY-MM");

      const nowMonth = moment().format("MM");
      const lastMonth = moment(this.bodyDataDaily.date_input).format("MM");
      if (nowMonth !== lastMonth) {
        this.dayNow = 31;
      } else {
        this.dayNow = moment().format("D");
      }
      const body = {
        company_id: company,
        oh_type: this.bodyDataDaily.oh_type,
        type_input: "Daily",
        date_input: moment(this.bodyDataDaily.date_input).format("YYYY-MM")
      };
      this.getLastMonthOhData();
      this.gettotalMonthOhData();
      this.getTotalMonthOhDataActivity();
      this.getLastMonthOhDataActivity();
      this.customGetOH();
      // this.getLink();
      this.load = true;
      this.$store.dispatch("detailManagement/getDataDaily", body).then(() => {
        this.load = false;
      });
      this.$store.commit("detailManagement/setCompanySelect", company);
    },

    isCompany() {
      const company = localStorage.getItem("company");
      return company;
    },

    contentEditableVisibility(type = 1) {
      if (type == 1) {
        return this.is_admin == "true"
          ? this.bodyDataDaily.company_id == this.isCompany() ||
              this.filterCompany == 1
          : this.bodyDataDaily.company_id == this.isCompany();
      } else {
        return this.is_admin == "true"
          ? this.bodyDataDaily.company_id != this.isCompany() &&
              this.filterCompany != 1
          : this.bodyDataDaily.company_id != this.isCompany();
      }
    },

    contentEditableCondition(row, idx_sub, type = 1) {
      var cond = "false";
      switch (type) {
        case 1:
          if (this.user_role[0].healthcare_facility_feature.findIndex((e) => parseInt(e.healthcare_facility_feature_id) != 1)) {
            cond =
            row.manual_input === 1 ||
            idx_sub + 1 > this.dayNow ||
            row.oh_id == "11" ||
            row.oh_id == "16" ||
            row.oh_id == "3" ||
            row.oh_id == "27" ||
            row.oh_id == "28" ||
            row.oh_id == "5" ||
            row.oh_id == "6" ||
            row.oh_id == "7" ||
            row.oh_id == "9"
              ? "false"
              : "true";
          break;
          } else {
            cond =
            row.manual_input === 1 ||
            idx_sub + 1 > this.dayNow ||
            row.oh_id == "11" ||
            row.oh_id == "16" 
            // row.oh_id == "3" ||
            // row.oh_id == "27" ||
            // row.oh_id == "28" ||
            // row.oh_id == "5" ||
            // row.oh_id == "6" ||
            // row.oh_id == "7" ||
            // row.oh_id == "9"
              ? "false"
              : "true";
          break;
          }
          
        case 2:
          if (this.is_admin == "true") {
            cond =
              row.manual_input === 1 || this.filterCompany == 1
                ? idx_sub + 1 > this.dayNow
                  ? "false"
                  : "true"
                : "false";
          } else {
            cond = 
            row.manual_input === 1 || idx_sub + 1 > this.dayNow ||
            row.oh_id == "11" ||
            row.oh_id == "16" 
            ? "false"
              : "true";
            // cond =
            //   row.manual_input === 1 ||
            //   this.bodyDataDaily.company_id == this.isCompany()
            //     ? "true"
            //     : "false";
          }
          break;
        default:
          break;
      }
      return cond;
    },
    contentEditableCondition2(row, idx_sub, type = 1) {
      var cond = "false";
      switch (type) {
        case 1:
          if (this.user_role[0].healthcare_facility_feature.findIndex((e) => parseInt(e.healthcare_facility_feature_id) != 1)) {
            cond =
            row.manual_input === 1 ||
            idx_sub + 1 > this.dayNow 
            // ||
            // row.oh_id == "11" ||
            // row.oh_id == "16" ||
            // row.oh_id == "3" ||
            // row.oh_id == "27" ||
            // row.oh_id == "28" ||
            // row.oh_id == "5" ||
            // row.oh_id == "6" ||
            // row.oh_id == "7" ||
            // row.oh_id == "9"
              ? "false"
              : "true";
          break;
          } else {
            cond =
            row.manual_input === 1 ||
            idx_sub + 1 > this.dayNow 
            // ||
            // row.oh_id == "11" ||
            // row.oh_id == "16" 
            // row.oh_id == "3" ||
            // row.oh_id == "27" ||
            // row.oh_id == "28" ||
            // row.oh_id == "5" ||
            // row.oh_id == "6" ||
            // row.oh_id == "7" ||
            // row.oh_id == "9"
              ? "false"
              : "true";
          break;
          }
          
        case 2:
          if (this.is_admin == "true") {
            cond =
              row.manual_input === 1 || this.filterCompany == 1
                ? idx_sub + 1 > this.dayNow
                  ? "false"
                  : "true"
                : "false";
          } else {
            cond = 
            row.manual_input === 1 || idx_sub + 1 > this.dayNow 
            // ||
            // row.oh_id == "11" ||
            // row.oh_id == "16" 
            ? "false"
              : "true";
            // cond =
            //   row.manual_input === 1 ||
            //   this.bodyDataDaily.company_id == this.isCompany()
            //     ? "true"
            //     : "false";
          }
          break;
        default:
          break;
      }
      return cond;
    },

    isContentDate(day, values) {
      const index = values.findIndex((e) => e.day === day);
      if (index > -1) {
        return values[index]["date"];
      } else {
        return "";
      }
    },

    isContentValue(day, values) {
      const index = values.findIndex((e) => e.day === day);
      if (index > -1) {
        return values[index]["value"];
      } else {
        return "";
      }
    },

    lastDate(date) {
      let last = moment(date).endOf("month").format("D");
      return parseInt(last);
    },

    dates(month) {
      return moment(month).format("MMMM YYYY");
    },
    lastmonth(month) {
      return moment(month).format("MMM YY");
    },

    Updates(evt, id0, id1, day, values) {
      this.$set(
        this.ohDaily[id0],
        id1,
        document.getElementById(id0 + "-" + id1).innerText
      );
      const index = values.findIndex((e) => e.day === day);
      const editValue = document.getElementById(id0 + "-" + id1).innerText;
      const editCel = parseFloat(editValue.replace(/,/g, ""));
      const dataValue = values[index]["value"];

      if (Number(dataValue) === Number(editCel)) {
        // console.log("if");
        // this.$swal
        // 	.mixin({
        // 		toast: true,
        // 		position: "top-end",
        // 		showConfirmButton: false,
        // 		timer: 3000,
        // 		timerProgressBar: true,
        // 		didOpen: (toast) => {
        // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
        // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        // 		},
        // 	})
        // 	.fire({
        // 		icon: "error",
        // 		title: "your input should not be the same as the previous data",
        // 	});
      } else if (Number(editCel) || Number(editCel) == 0) {
        let body = {
          id: values[index]["id"],
          value: editCel,
          is_deleted: false
        };

        this.$store
          .dispatch("inputOH/updateOhDaily", body)
          .then((resp) => {
            // this.$swal
            // 	.mixin({
            // 		toast: true,
            // 		position: "top-end",
            // 		showConfirmButton: false,
            // 		timer: 3000,
            // 		timerProgressBar: true,
            // 		didOpen: (toast) => {
            // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
            // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            // 		},
            // 	})
            // 	.fire({
            // 		icon: "success",
            // 		title: "Successfully Change",
            // 	})
            // 	.then((resp) => {
            // 		// this.customGetOH();
            // 		if (body.value) {
            // 			return body.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            // 		} else {
            // 			return "";
            // 		}
            // 	});
            if (body.value) {
              return body.value
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return "";
            }
          })
          .then((resp) => {
            this.load = false;
            this.bodyDataDaily.oh_type = "Data";
            // this.load = true
            this.customGetOH();
            this.getLastMonthOhData();
            this.gettotalMonthOhData();
            this.load = false;
          })
          .catch((resp) => {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: resp.response.data.error
              });
            this.isOnLoading = false;
          });
      } else {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "You input in Number"
          });
        this.isOnLoading = false;
      }
    },

    Update(evt, id0, id1, day, values) {
      let elId = document.getElementById(id0 + "-" + id1);
      this.$set(
        this.ohDaily[id0],
        id1,
        document.getElementById(id0 + "-" + id1).innerText
      );
      const index = values.findIndex((e) => e.day === day);
      const editValue = document.getElementById(id0 + "-" + id1).innerText;
      const editCel = parseFloat(editValue.replace(/,/g, ""));
      const dataValue = values[index]["value"];
      switch (evt.keyCode) {
        case 13:
          evt.preventDefault();
          elId = document.getElementById(parseInt(id0) + 1 + "-" + id1);
          elId.focus();
          if (Number(dataValue) === Number(editCel)) {
            // this.$swal
            // 	.mixin({
            // 		toast: true,
            // 		position: "top-end",
            // 		showConfirmButton: false,
            // 		timer: 3000,
            // 		timerProgressBar: true,
            // 		didOpen: (toast) => {
            // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
            // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            // 		},
            // 	})
            // 	.fire({
            // 		icon: "error",
            // 		title: "your input should not be the same as the previous data",
            // 	});
          } else if (Number(editCel) || Number(editCel) == 0) {
            let body = {
              id: values[index]["id"],
              value: editCel,
              is_deleted: false
            };

            this.$store
              .dispatch("inputOH/updateOhDaily", body)
              .then((resp) => {
                // this.$swal
                // 	.mixin({
                // 		toast: true,
                // 		position: "top-end",
                // 		showConfirmButton: false,
                // 		timer: 3000,
                // 		timerProgressBar: true,
                // 		didOpen: (toast) => {
                // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
                // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                // 		},
                // 	})
                // 	.fire({
                // 		icon: "success",
                // 		title: "Successfully Change",
                // 	})
                // 	.then((resp) => {
                // 		// this.customGetOH();
                // 		if (body.value) {
                // 			return body.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                // 		} else {
                // 			return "";
                // 		}
                // 	});
                if (body.value) {
                  return body.value
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return "";
                }
              })
              .then((resp) => {
                this.load = false;
                this.bodyDataDaily.oh_type = "Data";
                // this.load = true
                this.customGetOH();
                this.getLastMonthOhData();
                this.gettotalMonthOhData();
                this.load = false;
              })
              .catch((resp) => {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    }
                  })
                  .fire({
                    icon: "error",
                    title: resp.response.data.error
                  });
                this.isOnLoading = false;
              });
          } else {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: "You input in Number"
              });
            this.isOnLoading = false;
          }
          break;
        // case 37:
        // 	evt.preventDefault();
        // 	console.log("Left Arrow");
        // 	elId = document.getElementById(id0 + "-" + (parseInt(id1) - 1))
        // 	elId.focus()
        // 	elId.select()
        // 	break
        case 38:
          evt.preventDefault();
          elId = document.getElementById(parseInt(id0) - 1 + "-" + id1);
          elId.focus();
          elId.select();
          break;
        // case 39:
        // 	evt.preventDefault();
        // 	console.log("Right Arrow");
        // 	elId = document.getElementById(id0 + "-" + (parseInt(id1) + 1))
        // 	elId.focus()
        // 	elId.select()
        // 	break
        case 40:
          evt.preventDefault();
          // console.log("Down Arrow");
          elId = document.getElementById(parseInt(id0) + 1 + "-" + id1);
          elId.focus();
          elId.select();
          break;
        default:
          if (evt.keyCode >= 65 && evt.keyCode <= 90) {
            evt.preventDefault();
          }
          break;
      }
    },

    UpdatesActivity(evt, id0, id1, day, values) {
      this.$set(
        this.ohDaily[id0],
        id1,
        document.getElementById(id0 + "/" + id1).innerText
      );
      const index = values.findIndex((e) => e.day === day);
      const editValue = document.getElementById(id0 + "/" + id1).innerText;
      const editCel = parseFloat(editValue.replace(/,/g, ""));
      const dataValue = values[index]["value"];
      if (Number(dataValue) === Number(editCel)) {
        // this.$swal
        // 	.mixin({
        // 		toast: true,
        // 		position: "top-end",
        // 		showConfirmButton: false,
        // 		timer: 3000,
        // 		timerProgressBar: true,
        // 		didOpen: (toast) => {
        // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
        // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        // 		},
        // 	})
        // 	.fire({
        // 		icon: "error",
        // 		title: "your input should not be the same as the previous data",
        // 	});
      } else if (Number(editCel) || Number(editCel) == 0) {
        let body = {
          id: values[index]["id"],
          value: editCel,
          is_deleted: false
        };

        this.$store
          .dispatch("inputOH/updateOhDaily", body)
          .then((resp) => {
            // this.$swal
            // 	.mixin({
            // 		toast: true,
            // 		position: "top-end",
            // 		showConfirmButton: false,
            // 		timer: 3000,
            // 		timerProgressBar: true,
            // 		didOpen: (toast) => {
            // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
            // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            // 		},
            // 	})
            // 	.fire({
            // 		icon: "success",
            // 		title: "Successfully Change",
            // 	})
            // 	.then((resp) => {
            // 		// this.customGetOH();
            // 		if (body.value) {
            // 			return body.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            // 		} else {
            // 			return "";
            // 		}
            // 	});
            if (body.value) {
              return body.value
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
            } else {
              return "";
            }
          })
          .then((resp) => {
            this.load = false;
            this.bodyDataDaily.oh_type = "Activity";
            // this.load = true
            this.customGetOH();
            this.getTotalMonthOhDataActivity();
            this.load = false;
          })
          .catch((resp) => {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: resp.response.data.error
              });
            this.load = false;
          });
      } else {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            }
          })
          .fire({
            icon: "error",
            title: "You input in Number"
          });
        this.isOnLoading = false;
      }
    },

    UpdateActivity(evt, id0, id1, day, values) {
      let elId = document.getElementById(id0 + "-" + id1);
      this.$set(
        this.ohDaily[id0],
        id1,
        document.getElementById(id0 + "/" + id1).innerText
      );
      const index = values.findIndex((e) => e.day === day);
      const editValue = document.getElementById(id0 + "/" + id1).innerText;
      const editCel = parseFloat(editValue.replace(/,/g, ""));
      const dataValue = values[index]["value"];
      switch (evt.keyCode) {
        case 13:
          evt.preventDefault();
          elId = document.getElementById(parseInt(id0) + 1 + "/" + id1);
          elId.focus();
          if (Number(dataValue) === Number(editCel)) {
            // this.$swal
            // 	.mixin({
            // 		toast: true,
            // 		position: "top-end",
            // 		showConfirmButton: false,
            // 		timer: 3000,
            // 		timerProgressBar: true,
            // 		didOpen: (toast) => {
            // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
            // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            // 		},
            // 	})
            // 	.fire({
            // 		icon: "error",
            // 		title: "your input should not be the same as the previous data",
            // 	});
          } else if (Number(editCel) || Number(editCel) == 0) {
            let body = {
              id: values[index]["id"],
              value: editCel,
              is_deleted: false
            };

            this.$store
              .dispatch("inputOH/updateOhDaily", body)
              .then((resp) => {
                // this.$swal
                // 	.mixin({
                // 		toast: true,
                // 		position: "top-end",
                // 		showConfirmButton: false,
                // 		timer: 3000,
                // 		timerProgressBar: true,
                // 		didOpen: (toast) => {
                // 			toast.addEventListener("mouseenter", this.$swal.stopTimer);
                // 			toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                // 		},
                // 	})
                // 	.fire({
                // 		icon: "success",
                // 		title: "Successfully Change",
                // 	})
                // 	.then((resp) => {
                // 		// this.customGetOH();
                // 		if (body.value) {
                // 			return body.value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                // 		} else {
                // 			return "";
                // 		}
                // 	});
                if (body.value) {
                  return body.value
                    .toString()
                    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
                } else {
                  return "";
                }
              })
              .then((resp) => {
                this.load = false;
                this.bodyDataDaily.oh_type = "Activity";
                // this.load = true
                this.customGetOH();
                this.getTotalMonthOhDataActivity();
                this.load = false;
              })
              .catch((resp) => {
                this.$swal
                  .mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener(
                        "mouseenter",
                        this.$swal.stopTimer
                      );
                      toast.addEventListener(
                        "mouseleave",
                        this.$swal.resumeTimer
                      );
                    }
                  })
                  .fire({
                    icon: "error",
                    title: resp.response.data.error
                  });
                this.isOnLoading = false;
              });
          } else {
            this.$swal
              .mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", this.$swal.stopTimer);
                  toast.addEventListener("mouseleave", this.$swal.resumeTimer);
                }
              })
              .fire({
                icon: "error",
                title: "You input in Number"
              });
            this.isOnLoading = false;
          }
          break;
        // case 37:
        // 	evt.preventDefault();
        // 	console.log("Left Arrow");
        // 	elId = document.getElementById(id0 + "/" + (parseInt(id1) - 1))
        // 	elId.focus()
        // 	elId.select()
        // 	break
        case 38:
          evt.preventDefault();
          // console.log("Up Arrow");
          elId = document.getElementById(parseInt(id0) - 1 + "/" + id1);
          elId.focus();
          elId.select();
          break;
        // case 39:
        // 	evt.preventDefault();
        // 	console.log("Right Arrow");
        // 	elId = document.getElementById(id0 + "/" + (parseInt(id1) + 1))
        // 	elId.focus()
        // 	elId.select()
        // 	break
        case 40:
          evt.preventDefault();
          // console.log("Down Arrow");
          elId = document.getElementById(parseInt(id0) + 1 + "/" + id1);
          elId.focus();
          elId.select();
          break;
        default:
          if (evt.keyCode >= 65 && evt.keyCode <= 90) {
            evt.preventDefault();
          }
          break;
      }
    },
    //action

    saveAdd() {
      this.$validator.validateAll().then(async (res) => {
        if (!res) return false;
        try {
          let concat = this.record.concat(this.recordAct);
          for (let i = 0; i < concat.length; i++) {
            delete concat[i].description;
            // delete concat[i].type_data;
            delete concat[i].is_mandatory;
            delete concat[i].type_input;
            delete concat[i].status;
            // delete concat[i].oh_type;
          }
          let payload = {
            record: concat,
            type_input: "Daily",
            date_input: this.addBodyDataDaily.date_input
          };
          this.isOnLoading = true;
          this.$store
            .dispatch("detailManagement/addOhDaily", payload)
            .then((resp) => {
              this.load = true;
              this.isOnLoading = false;
              this.$swal({
                title: this.$t("Saved"),
                text: this.$t("Your input has been saved"),
                icon: "success",
                confirmButtonText: this.$t("OK")
              }).then((resp) => {
                this.customGetOH();
                this.getTotalMonthOhDataActivity();
                this.getTotalMonthOhDataActivity();
                this.$bvModal.hide("modal-1");
                // this.addBodyDataDaily.date_input = "",
                // this.record = [],
                // this.recordAct = []
                this.addBodyDataDaily.date_input = "";
                const datas = this.$store.getters["ohData/OhData"];
                const list = datas.data;
                let rec = list.map((item) => {
                  return {
                    oh_type: "Data",
                    oh_id: Number(item.id),
                    oh_label: item.data_name,
                    value:
                      item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
                  };
                });
                this.record = rec;
                const datass = this.$store.getters["activity/ActivityData"];
                const listt = datass.data;
                let recA = listt.map((item) => {
                  return {
                    oh_type: "Activity",
                    oh_id: Number(item.id),
                    oh_label: item.activity_name,
                    value:
                      item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
                  };
                });
                this.recordAct = recA;
              });
            })
            .catch((resp) => {
              this.$swal(
                `Error ${resp.response.data.httpCode}`,
                resp.response.data.error,
                "error"
              );
              this.isOnLoading = false;
            });
        } catch (resp) {
          this.$swal("Error", this.$t("Error while add data"), "error");
          this.isOnLoading = false;
        }
      });
    },

    // api hit
    getOh() {
      this.$store.dispatch("ohData/getOh", this.body).then(() => {
        const datas = this.$store.getters["ohData/OhData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Data",
            oh_id: Number(item.id),
            oh_label: item.data_name,
            manual_input: item.manual_input,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.record = rec;
      });
    },
    getOhDaily() {
      this.loadDate = true;
      this.$store
        .dispatch("detailManagement/getDataDailyInput", this.addBodyDataDaily)
        .then(() => {
          this.loadDate = false;
          const datas =
            this.$store.getters["detailManagement/OHDailyDataInput"];
          const list = datas;
          let rec = list.map((item) => {
            // const index = item.values.findIndex((e) => e.date === this.date_input);
            return {
              is_mandatory: item.is_mandatory,
              description: item.description,
              type_data: item.type_data,
              type_input: "Daily",
              oh_type: item.oh_type,
              oh_id: Number(item.oh_id),
              oh_label: item.oh_label,
              status: item.status,
              manual_input: item.manual_input,
              value: item.values[0].value
            };
          });

          var rec2 = rec.filter((i) => {
            return i.oh_type == "Data";
          });

          var rec3 = rec.filter((i) => {
            return i.oh_type == "Activity";
          });
          var status = rec.map((i) => {
            return i.status;
          });
          var dat = status.reduce((total, value) => total + value, 0);
          this.statusRecord = dat;
          this.record = rec2;
          this.recordAct = rec3;
        });
    },
    customGetOH() {
      this.load = true;
      this.$store
        .dispatch("detailManagement/getDataDaily", this.bodyDataDaily)
        .then(() => {
          this.load = false;
        });
    },

    getCompanny() {
      this.$store.dispatch("detailManagement/getCompany");
    },
    getLink() {
      let body = {
        data: "Daily",
        date_input: this.bodyDataDaily.date_input,
        company_id: this.bodyDataDaily.company_id
      };
      this.$store.dispatch("summary/downloadExcel", body);
    },
    getActivity() {
      this.$store.dispatch("activity/getActivity", this.bodys).then(() => {
        const datas = this.$store.getters["activity/ActivityData"];
        const list = datas.data;
        let rec = list.map((item) => {
          return {
            oh_type: "Activity",
            oh_id: Number(item.id),
            oh_label: item.activity_name,
            manual_input: item.manual_input,
            value: item.type_data == "Integer" && !item.is_mandatory ? 0 : ""
          };
        });
        this.recordAct = rec;
      });

      // this.customGetActivity();
    },

    lastUpdate() {
      // let body = {
      //   user_id : this.user_id
      // }
      // this.$store.dispatch("detailManagement/getLastUpdate",body);
      this.$store.dispatch("detailManagement/getLastUpdate");
    },

    // filter
    filterPage() {
      this.getOh();
      this.getActivity();
    },

    sorting() {
      // this.load = true;
      const lastday = moment(this.bodyDataDaily.date_input)
        .endOf("month")
        .format("D");
      this.lastday = Number(lastday);
      const nowMonth = moment().format("MM");
      const lastMonth = moment(this.bodyDataDaily.date_input).format("MM");
      if (nowMonth !== lastMonth) {
        this.dayNow = 31;
      } else {
        this.dayNow = moment().format("D");
      }
      this.customGetOH();
      // this.getLink();
      this.lastMothbodyDataDaily.date_input = moment(
        this.bodyDataDaily.date_input
      )
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM");
      this.lastMothbodyActivityDaily.date_input = moment(
        this.bodyDataDaily.date_input
      )
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM");

      this.totalbodyDataDaily.date_input = moment(
        this.bodyDataDaily.date_input
      ).format("YYYY-MM");
      this.totalbodyActivityDaily.date_input = moment(
        this.bodyDataDaily.date_input
      ).format("YYYY-MM");
      this.getLastMonthOhData();
      this.getLastMonthOhDataActivity();
      this.gettotalMonthOhData();
      this.getTotalMonthOhDataActivity();
    },

    // Pagination
    onPageChange(payload) {
      this.body.limit = payload.currentPerPage;
      this.body.page = payload.currentPage;

      if (
        parseInt(payload.currentPage) * parseInt(payload.currentPerPage) >
        1000
      ) {
        this.getOh();
      }
    },

    type1() {
      this.bodyDataDaily.oh_type = "Data";
      // this.load = true
      this.customGetOH();
    },
    type2() {
      this.bodyDataDaily.oh_type = "Activity";
      // this.load = true
      this.customGetOH();
    },
    download() {
      let body = {
        data: "Daily",
        date_input: this.bodyDataDaily.date_input,
        company_id: this.bodyDataDaily.company_id
      };
      this.$store.dispatch("summary/downloadExcel", body).then(() => {
        let link = this.$store.getters["summary/excelUrl"];
        if (link.hasOwnProperty("path")) {
          window.open(axiosIns.defaults.baseURL + "/" + link.path);
        } else {
          this.$swal("Error", link.data.error, "error");
          console.log(link.data.error);
        }
      });

      // window.location.href = axiosIns.defaults.baseURL + "/" + url;
    },
    Datenow() {
      const now = moment().format("YYYY-MM-DD");
      return now;
    },

    MonthNow() {
      const now = moment().format("YYYY-MM");
      return now;
    },

    convertNumber(num) {
      if (num) {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  }
};
</script>
