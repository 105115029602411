<template>
  <!-- ButtonExport -->

  <div class="row align-items-center justify-content-between">
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">Patient Info</h4>
      </div>
      <div v-if="load === false">
        <div class="list-group list-group-flush">
          <div class="list-group-item p-4">
            <div class="row">
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-credit-card"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>NIK</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.nik }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-user"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Name</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.patient.patient_name }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-home"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Company</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.company }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-layers"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Departement</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.department }}</strong>
                </p>
              </div>
              <div class="col-auto">
                <!-- Avatar -->
                <div class="avatar avatar-sm">
                  <div
                    class="avatar-title fs-lg bg-primary-soft rounded-circle text-primary">
                    <i class="fe fe-award"></i>
                  </div>
                </div>
              </div>
              <div class="col">
                <p class="small mt-2">
                  <strong>Position</strong>
                </p>
                <p class="small mt-2">
                  <strong>{{ this.history.position }}</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body text-center" v-else>
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="card p-0">
      <div class="card-header">
        <!-- Title -->
        <h4 class="card-header-title">MCU Record</h4>
        <!-- Button -->
        <div style="margin-right: 15px"></div>
      </div>
      <vue-good-table
        :line-numbers="true"
        :columns="columns"
        :rows="dataMedical.data"
        :sort-options="{
          enabled: true
        }"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          position: 'bottom',
          perPage: 10,
          perPageDropdown: [10, 50, 100],
          dropdownAllowAll: false,
          setCurrentPage: 1,
          nextLabel: 'next',
          prevLabel: 'prev',
          rowsPerPageLabel: 'Rows per page',
          ofLabel: 'of',
          pageLabel: 'page',
          allLabel: 'All'
        }"
        style-class="vgt-table border-0 table">
        <!-- Loading -->
        <div slot="emptystate" class="text-center font-size-14">
          No data available
        </div>
        <!-- Custom Rows -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'register_date'">
            {{ date(props.row.register_date) }}
          </span>
          <!-- Table Action -->
          <span v-else-if="props.column.field == 'action'">
            <div>
              <b-button
                class="no-wrap btn-pill mr-2"
                variant="info"
                size="sm"
                @click.prevent="viewRecord(props.row)">
                <span class="fe fe-eye"></span>
              </b-button>

              <b-button
                class="no-wrap btn-pill mr-2"
                variant="danger"
                size="sm"
                v-b-modal.modal-loading
                title="Download PDF"
                @click.prevent="exportPdf(props.row.register_id)">
                <span class="fe fe-download"></span>
              </b-button>
              <!-- @click.prevent="exportCertifPdf(props.row.register_id)" -->
              <b-button
                v-if="
                  (filterRoles === 4 &&
                    props.row.verification_status == 'RECORDED') ||
                  (filterRoles === 4 &&
                    props.row.verification_status == 'DISAPPROVED') ||
                  (filterRoles === 4 &&
                    props.row.verification_status == 'PENDING')
                "
                title="Approval"
                v-b-modal.modal-approval
                class="no-wrap btn-pill mr-2"
                variant="warning"
                @click.prevent="clickApproval(props.row)"
                size="sm">
                <span class="fe fe-info"></span>
              </b-button>
              <!-- v-if="filterRoles === 4" -->
              <!-- {{ dataMedical.data }} -->
              <b-button
                v-if="
                  filterRoles === 4 &&
                  props.row.verification_status == 'APPROVED'
                "
                v-b-tooltip.hover
                title="Download Certificate"
                class="no-wrap btn-pill"
                variant="success"
                @click.prevent="exportCertifPdf(props.row.register_id)"
                size="sm">
                <span class="fe fe-file-text"></span>
              </b-button>
            </div>
          </span>
        </template>
      </vue-good-table>
      <!-- <b-modal id="modal-loading" size="sm" title="Please Wait" hide-footer>
       <div class="card-body text-center" v-if="load == true">
        <span class="spinner-border spinner-border-sm" role="status">
          <span class="visually-hidden">Loading...</span>
        </span>
      </div>
      </b-modal> -->
      <b-modal
        id="modal-loading"
        size="sm"
        centered
        hide-header
        hide-footer
        no-close-on-backdrop
        no-close-on-esc>
        <b class="d-flex justify-content-center mb-3">Please wait</b>
        <div class="d-flex justify-content-center">
          <b-spinner variant="primary" label="Loading"></b-spinner>
        </div>
      </b-modal>
      <!-- buat commmit -->
      <b-modal id="modal-approval" size="xl" title="Approval Form" hide-footer>
        <b-tabs justified>
          <b-tab title="MCU Record" style="padding: 0px">
            <b-tabs justified>
              <b-tab title="Riwayat" style="padding: 0px">
                <Riwayat />
              </b-tab>
              <b-tab
                title="Laboratorium & Non Laboratorium"
                style="padding: 0px">
                <Lab />
              </b-tab>
              <b-tab title="Fisik" style="padding: 0px">
                <Fisik />
              </b-tab>
            </b-tabs>
          </b-tab>
          <b-tab title="Approval" style="padding: 0px">
            <b-tabs justified v-model="tabIndex">
              <b-tab title="Pemeriksaan" style="padding: 0px">
                <div v-for="(item, index) in questionFinal" :key="index">
                  <div v-if="item.question_type_id == 1">
                    <div
                      class="row mt-4"
                      style="margin-left: 15px; margin-right: 15px">
                      <div class="col-12">
                        <b-form-group>
                          <label class="form-label"
                            >{{ index + 1 }}. {{ item.question }}</label
                          >
                          <b-form-radio-group
                            v-model="item.answer"
                            :options="options"
                            plain></b-form-radio-group>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <b-button
                  class="float-end"
                  variant="primary"
                  @click="tabIndex++"
                  ><span class=""></span> Next</b-button
                >
              </b-tab>
              <b-tab title="Catatan" style="padding: 0px">
                <div v-for="(item, index) in questionFinal" :key="index">
                  <div v-if="item.question_type_id == 2">
                    <div
                      class="row mt-4"
                      style="margin-left: 15px; margin-right: 15px">
                      <div class="col-12">
                        <b-form-group>
                          <label class="form-label"
                            >{{ index + 1 }}. {{ item.question }}</label
                          >
                          <b-form-radio-group
                            v-model="item.answer"
                            :options="options"
                            plain>
                          </b-form-radio-group>
                        </b-form-group>
                        <textarea
                          class="form-control"
                          placeholder="Notes"
                          v-if="item.answer == 'YES'" />
                      </div>
                    </div>
                  </div>
                </div>
                <b-button
                  class="float-end mt-4"
                  variant="primary"
                  @click="tabIndex++"
                  ><span class=""></span> Next</b-button
                >
                <b-button
                  class="float-end mr-3 mt-4"
                  variant="primary"
                  @click="tabIndex--"
                  ><span class=""></span> Back</b-button
                >
              </b-tab>
              <b-tab title="Saran" style="padding: 0px" @click="getProgram">
                <div
                  class="row mt-4"
                  style="margin-left: 15px; margin-right: 15px">
                  <div class="col-12">
                    <b-form-group>
                      <label class="mt-4">Program</label>
                      <span style="color: red">*</span>
                      <v-select
                        v-model="bodyApproval.program"
                        :options="programList"
                        label="program_name"
                        :reduce="(e) => e.id"
                        required />
                      <label class="mt-4">SARAN KESEHATAN</label>
                      <span style="color: red">*</span>
                      <textarea
                        class="form-control"
                        v-model="bodyApproval.saran_kesehatan"
                        placeholder="Saran Kesehatan" />
                      <label class="mt-4">START DATE</label>
                      <span style="color: red">*</span>
                      <input
                        type="date"
                        class="form-control"
                        v-model="bodyApproval.startDate" />
                      <label class="mt-4">END DATE</label>
                      <span style="color: red">*</span>
                      <input
                        type="date"
                        class="form-control"
                        v-model="bodyApproval.endDate" />
                      <label class="mt-4">FREQUENCY</label>
                      <span style="color: red">*</span>
                      <input
                        type="text"
                        class="form-control"
                        v-model="bodyApproval.frequency" />
                      <label class="mt-4">UNIT</label>
                      <span style="color: red">*</span>
                      <v-select
                        v-model="bodyApproval.unit"
                        :options="optionsUnit"
                        label="unit"
                        required />
                    </b-form-group>
                  </div>
                </div>
                <b-button
                  class="float-end"
                  variant="primary"
                  @click="tabIndex++"
                  ><span class=""></span> Next</b-button
                >
                <b-button
                  class="float-end mr-2"
                  variant="primary"
                  @click="tabIndex--"
                  ><span class=""></span> Back</b-button
                >
              </b-tab>
              <b-tab
                title="Kesimpulan"
                style="padding: 0px"
                @click="getKesimpulan">
                <div v-for="(item, index) in questionFinal" :key="index">
                  <div v-if="item.question_type_id == 3">
                    <div
                      class="row mt-4"
                      style="margin-left: 15px; margin-right: 15px">
                      <div class="col-4">
                        <b-form-group>
                          <label class="form-label"
                            >{{ index + 1 }}. {{ item.question }}</label
                          >
                          <b-form-radio-group
                            v-model="item.answer"
                            @input="inputKesimpulan(item.answer)"
                            :options="optionsKesimpulan"></b-form-radio-group>
                        </b-form-group>
                      </div>
                      <div
                        v-if="item.answer == optionsKesimpulan[0]"
                        class="row mt-3">
                        <div class="col-4">
                          <b-form-group>
                            <label>Tanggal MCU Berikutnya</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="bodyApproval.mcuDateLolos" />
                          </b-form-group>
                        </div>
                      </div>
                      <div
                        v-if="item.answer == optionsKesimpulan[2]"
                        class="row mt-3">
                        <div class="col-4">
                          <b-form-group>
                            <label>Tanggal MCU Hari Ini</label>
                            <input
                              type="date"
                              class="form-control"
                              v-model="bodyApproval.mcuDateTidakLolos" />
                          </b-form-group>
                        </div>
                        <label>Notes</label>
                        <textarea
                          style="margin-left: 15px; margin-right: 15px"
                          class="form-control"
                          placeholder="Notes" />
                      </div>
                      <label v-if="item.answer == optionsKesimpulan[1]"
                        >Notes</label
                      >
                      <textarea
                        style="margin-left: 15px; margin-right: 15px"
                        class="form-control"
                        placeholder="Notes"
                        v-if="item.answer == optionsKesimpulan[1]" />
                    </div>
                  </div>
                </div>
                <div
                  class="row mt-4"
                  style="margin-left: 15px; margin-right: 15px">
                  <div class="col-3">
                    <b-form-group>
                      <label>STATUS KESEHATAN</label>
                      <!-- {{ status_kesehatanDef }} -->
                      <v-select
                        v-model="bodyApproval.status_kesehatan"
                        :options="optionsStatus"
                        label="result_name"
                        :reduce="(e) => e.id" />
                    </b-form-group>
                  </div>
                </div>
                <div
                  class="row mt-4"
                  style="margin-left: 15px; margin-right: 15px">
                  <div class="col-6">
                    <b-form-group>
                      <label>TANDA TANGAN DOKTER(.png)</label>
                      <br />
                      <i
                        >*Upload tanda tangan jika belum pernah melakukan upload
                        tanda tangan
                      </i>
                      <input
                        class="mt-2"
                        type="file"
                        id="fileInput"
                        label="Upload Image"
                        accept=".png"
                        @change="handleFileChange" />
                      <br />
                      <button
                        v-if="bodyApproval.signaturePreview"
                        @click="removeImage"
                        class="btn btn-danger mt-2 float-end">
                        X
                      </button>
                      <img
                        v-if="bodyApproval.signaturePreview"
                        :src="bodyApproval.signaturePreview"
                        style="
                          max-width: 100%;
                          margin-top: 8px;
                          height: auto;
                        " />
                    </b-form-group>
                  </div>
                </div>
                <b-button
                  class="float-end"
                  variant="primary"
                  @click="approval()"
                  ><span class=""></span> Submit</b-button
                >
                <b-button
                  class="float-end mr-3"
                  variant="primary"
                  @click="tabIndex--"
                  ><span class=""></span> Back</b-button
                >
              </b-tab>
            </b-tabs>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
  </div>
</template>
<script>
import Riwayat from "@/components/clinic_management/mcu/riwayat.vue";
import NonLab from "@/components/clinic_management/mcu/non_lab.vue";
import Lab from "@/components/clinic_management/mcu/lab.vue";
import Fisik from "@/components/clinic_management/mcu/fisik.vue";
import moment from "moment";
import axiosIns from "@/api/axios";
export default {
  name: "patient",
  components: {
    Riwayat,
    NonLab,
    Lab,
    Fisik
  },
  computed: {
    dataMCURecord() {
      return this.$store.getters["historyMcu/mcuRecordData"]
        ? this.$store.getters["historyMcu/mcuRecordData"]
        : [];
    },
    resultList() {
      return this.$store.getters["resultMcu/ResultData"]
        ? this.$store.getters["resultMcu/ResultData"]
        : [];
    },
    dataMedical() {
      return this.$store.getters["historyMcu/patientRecordData"]
        ? this.$store.getters["historyMcu/patientRecordData"]
        : [];
    },
    questionList() {
      return this.$store.getters["question/QuestionData"];
    }

    // questionType (){
    //   return this.$store.getters["typeQuestion/questionDataType"]
    //   ? this.$store.getter["typeQuestion/questionDataType"]
    //   :[]
    // }
  },
  data() {
    const formattedDate = moment().add(1, "years").format("YYYY-MM-DD");
    return {
      // body:{
      //   page: 1,
      //   limit: 10,
      //   order: "",
      // },
      selectedImage: null,
      imagePreview: null,
      programList: [],
      program: "",
      tabIndex: 0,
      register_id: "",
      kesimpulan: [],
      rekomendasi: [],
      status_kesehatan: [],
      date_visit: "",
      bodyApproval: {
        signaturePreview: "",
        signature: "",
        status_kesehatan: "",
        program: "",
        startDate: moment(this.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
        frequency: "",
        unit: "",
        saran_kesehatan: "",
        mcuDateLolos: formattedDate,
        mcuDateTidakLolos: moment(this.mcuDateTidakLolos).format("YYYY-MM-DD")
      },
      direct: "Riwayat",
      selected: [
        {
          pemeriksaan_fisik: "",
          pemeriksaan_mata: "",
          pemeriksaan_darah: "",
          pemeriksaan_urin: "",
          kimia_darah: "",
          ginjal: "",
          serologi_hepatitis: "",
          pemeriksaan_thorax: "",
          pemeriksaan_ekg: "",
          drug_screening: "",
          pemeriksaan_tambahan: "",
          pemeriksaan_tambahan_value: "",
          kelengkapan_berkas: "",
          kelengkapan_berkas_value: "",
          belum_diperiksa: "",
          belum_diperiksa_value: "",
          lulus: "",
          lulus_value: "",
          pending_menunggu: "",
          pending_menunggu_value: "",
          tidak_direkomendasikan: "",
          tidak_direkomendasikan_value: "",
          kesimpulan: ""
        }
      ],
      columns: [
        {
          label: "DATE",
          field: "register_date",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "LAB NAME",
          field: "mcu_location",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "NOREG",
          field: "register_id",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "TYPE MCU",
          field: "mcu_category",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "No Lab",
          field: "no_lab",
          thClass: "table-header no-wrap w-auto text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        },
        {
          label: "Status",
          field: "status",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle"
        },
        {
          label: "Verification",
          field: "verification_status",
          thClass: "table-header w-auto text-muted font-weight-bold",
          tdClass: "w-auto color-secondary align-middle"
        },
        {
          label: "ACTION",
          field: "action",
          thClass: "table-header no-wrap text-muted font-weight-bold",
          tdClass: "no-wrap color-secondary align-middle"
        }
      ],
      patient: [],
      history: [],
      pdf: {},
      load: false,
      options: [
        { text: "YES", value: "YES" },
        { text: "NO", value: "NO" }
      ],
      optionsKesimpulan: [
        "Karyawan Ini Lulus Ke Tahap Selanjutnya",
        "Pending Untuk Menunggu Berkas",
        "Tidak Direkomendasikan"
      ],
      optionsStatus: [
        "FIT FOR WORK",
        "FIT WITH NOTE",
        "FIT WITH RESTRICTION",
        "TEMPORARY UNFIT",
        "UNFIT"
      ],
      optionsUnit: ["Month", "Week", "Day"],
      user_id: "",
      status_kesehatanDef: "",
      questionFinal: [],
      filterRoles: "",
      filter_verification: ""
    };
  },
  mounted() {
    const roles = localStorage.getItem("roles_id");

    let historyId = localStorage.getItem("seehistory");
    const parseHistory = JSON.parse(historyId);

    this.register_id = parseHistory ? parseHistory.register_id : null;

    // this.filter_verification=dataMedical.data.filter(
    //   (item) => item.verification_Status === "APPROVED"
    // )

    this.filterRoles = parseInt(roles) == 4 ? 4 : 0;
    this.patient = JSON.parse(localStorage.getItem("dataMedical"));
    this.history = JSON.parse(localStorage.getItem("seehistory"));
    this.getDataProgram();
    this.getUnit();
    this.getResult();
    this.getDataColomnfisik();
  },
  methods: {
    // selectUnitOption(){
    //   const unit = this.
    // }

    handleFileChange(event) {
      const file = event.target.files[0];

      // Assuming you want to store a URL or blob for preview
      this.bodyApproval.signaturePreview = URL.createObjectURL(file);
      // If you want to store the file itself
      this.bodyApproval.signature = file;
    },

    removeImage() {
      // Remove the image preview and clear the file input value
      this.bodyApproval.signaturePreview = null;
      // this.imagePreview = null;
      // document.getElementById("fileInput").value = "";
    },

    selectResultOption() {
      const result = this.resultList;
      const term = [];
      result.map((i) => {
        term.push({
          id: i.id,
          result_name: i.result_name
        });
      });
      return term;
    },
    getResult() {
      this.$store.dispatch("resultMcu/getResult", this.body);
      // this.customStore();
    },
    clickApproval(e) {
      this.register_id = e.register_id;
      localStorage.setItem("seehistory", JSON.stringify(e));
      this.$store.dispatch("question/getQuestion", this.body).then(() => {
        let datas = this.$store.getters["question/QuestionData"].data;
        let question = datas.map((e) => {
          return {
            id: e.id,
            question: e.question,
            question_type_id: e.question_type_id,
            answer: "YES",
            notes: ""
          };
        });
        this.questionFinal = question;
      });
    },
    // getQuestion(){
    //   this.$store.dispatch("question/getQuestion", this.body);
    // },
    // getQuestionType(){
    //   this.$store.dispatch("typeQuestion/getQuestionType", this.body);
    // },
    filterVerif() {
      return this.dataMedical.data.filter(
        (item) => item.verification_status == "APPROVED"
      );
    },
    async convertFileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result.split(",")[1]);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    approval2() {
      let body = this.questionFinal.map((e) => {
        let dateVisit = "";
        if (e.answer === "Tidak Direkomendasikan") {
          dateVisit = this.bodyApproval.mcuDateTidakLolos;
        } else {
          dateVisit = this.bodyApproval.mcuDateLolos;
        }
        return {
          register_id: this.register_id,
          question_id: e.id,
          question_type_id: e.question_type_id,
          answer: e.answer,
          notes: e.notes,
          date_visit: dateVisit
        };
      });

      let bodySaran = {
        user_id: this.user_id,
        program_id: this.bodyApproval.program,
        health_notes: this.bodyApproval.saran_kesehatan,
        start_date: this.bodyApproval.startDate,
        end_date: this.bodyApproval.endDate,
        unit: this.bodyApproval.unit,
        frequency: this.bodyApproval.frequency
      };
      let bodyApproval = {
        question: body,
        status_kesehatan: this.bodyApproval.status_kesehatan,
        signature: base64String
      };

      axiosIns
        .post(
          `${axiosIns.defaults.baseURL}/clinic/patient/mcu/answer/submit`,
          bodyApproval
        )
        .then(() => {
          axiosIns.post(
            `${axiosIns.defaults.baseURL}/daily/healthProgram/user/create`,
            bodySaran
          );
        })
        .then((resp) => {
          this.$swal({
            icon: "success",
            title: "Success",
            text: "Patient has verification has been changed"
          }).then(() => {
            this.$bvModal.hide("modal-approval");
            this.getUnit();
            this.bodyApproval.status_kesehatan = "";
          });
        })
        .catch((resp) => {
          this.$swal("Error", `${resp.response.data.error}`, "error");
        });
    },
    approval() {
      let body = this.questionFinal.map((e) => {
        let dateVisit = "";
        if (e.answer === "Tidak Direkomendasikan") {
          dateVisit = this.bodyApproval.mcuDateTidakLolos;
        } else {
          dateVisit = this.bodyApproval.mcuDateLolos;
        }
        return {
          register_id: this.register_id,
          question_id: e.id,
          question_type_id: e.question_type_id,
          answer: e.answer,
          notes: e.notes,
          date_visit: dateVisit
        };
      });

      let fileInput = document.getElementById("fileInput");
      let signatureFile = fileInput.files[0];

      // Convert the file to a binary format
      let reader = new FileReader();
      reader.readAsBinaryString(signatureFile);

      reader.onload = () => {
        let binarySignature = btoa(reader.result);

        let bodySaran = {
          user_id: this.user_id,
          program_id: this.bodyApproval.program,
          health_notes: this.bodyApproval.saran_kesehatan,
          start_date: this.bodyApproval.startDate,
          end_date: this.bodyApproval.endDate,
          unit: this.bodyApproval.unit,
          frequency: this.bodyApproval.frequency
        };

        let bodyApproval = {
          question: body,
          status_kesehatan: this.bodyApproval.status_kesehatan,
          signature: binarySignature
        };

        axiosIns
          .post(
            `${axiosIns.defaults.baseURL}/clinic/patient/mcu/answer/submit`,
            bodyApproval
          )
          .then(() => {
            axiosIns.post(
              `${axiosIns.defaults.baseURL}/daily/healthProgram/user/create`,
              bodySaran
            );
          })
          .then(() => {
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Patient verification status has been changed"
            }).then(() => {
              this.$bvModal.hide("modal-approval");
              this.getUnit();
              this.bodyApproval.status_kesehatan = "";
            });
          })
          .catch((resp) => {
            this.$swal("Error", `${resp.response.data.error}`, "error");
          });
      };

      reader.onerror = () => {
        console.error("Error reading the file");
      };
    },

    viewRecord(data) {
      // console.log('MCUID :>>', data);
      // localStorage.setItem("MCUID", id);
      localStorage.setItem("seehistory", JSON.stringify(data));
      this.$router.push("/clinic-mcu/history/record-mcu");
    },
    exportPdf(id) {
      // localStorage.setItem("MCUID", id);
      localStorage.setItem("seeHistory", id);
      this.$bvModal.show("modal-loading");
      let body = {
        register_id: id,
        pdf_type: "MCU"
      };
      let params = queryString.stringify(body);
      axiosIns
        .get(`${axiosIns.defaults.baseURL}/clinic/export-pdf?${params}`)
        .then((resp) => {
          this.load = false;
          let pdf = resp.data.data.path;
          this.$bvModal.hide("modal-loading");
          window.open(`${axiosIns.defaults.baseURL}/${pdf}`);
          // setTimeout()
        })
        .catch((resp) => {
          this.$swal("Error", `${resp.response.data.error}`, "error");
          this.load = false;
          this.$bvModal.hide("modal-loading");
        });
    },
    exportCertifPdf(id) {
      // localStorage.setItem("MCUID", id);
      localStorage.setItem("seeHistory", id);
      this.$bvModal.show("modal-loading");

      let body = {
        register_id: id,
        pdf_type: "VERIFICATION_MCU"
      };
      let params = queryString.stringify(body);
      axiosIns
        .get(`${axiosIns.defaults.baseURL}/clinic/export-pdf?${params}`)
        .then((resp) => {
          let pdf = resp.data.data.path;
          this.$bvModal.hide("modal-loading");
          window.open(`${axiosIns.defaults.baseURL}/${pdf}`);
        })
        .catch((resp) => {
          this.$swal("Error", `${resp.response.data.error}`, "error");
        });
    },

    getUnit() {
      let body = {
        type_visit: "MCU",
        nik: this.patient.nik,
        employee_name: this.patient.patient_name
      };
      this.$store.dispatch("historyMcu/getRecordPatient", body);
    },

    date(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    inputKesimpulan(val) {
      let body = {
        type_visit: "MCU",
        category_name: "Identitas & Pemeriksaan Fisik",
        id: this.register_id
      };

      axiosIns
        .get(
          `${
            axiosIns.defaults.baseURL
          }/clinic/patient/mcu?type_visit=MCU&category_name=${body.category_name
            .toString()
            .replace("&", "%26")}&id=${body.id}`
        )
        .then((e) => {
          let gets = e.data.data.data[0].mcu[0].mcu_record[0];
          this.status_kesehatanDef = gets.detail_record.filter(this.filtering3);
          // this.bodyApproval.status_kesehatan = this.status_kesehatanDef[0].record[0].check_value
        });
      if (val == "Tidak Direkomendasikan") {
        this.bodyApproval.status_kesehatan = "UNFIT";
      } else if (val == "Pending Untuk Menunggu Berkas") {
        this.bodyApproval.status_kesehatan =
          this.status_kesehatanDef[0].record[0].check_value;
      } else if (val == "Karyawan Ini Lulus Ke Tahap Selanjutnya") {
        this.bodyApproval.status_kesehatan = "FIT FOR WORK";
      }
    },

    getKesimpulan() {
      this.getDataColomnfisik();
    },

    getProgram() {
      this.getDataProgram();
    },

    getDataProgram() {
      axiosIns
        .get(`${axiosIns.defaults.baseURL}/daily/healthProgram?`, this.body)
        .then((resp) => {
          this.programList = resp.data.data.data;
        });
    },

    getDataColomnfisik() {
      let body = {
        type_visit: "MCU",
        category_name: "Identitas & Pemeriksaan Fisik",
        id: this.register_id
      };
      // axiosIns.get(
      //   `${
      //     axiosIns.defaults.baseURL
      //   }/clinic/patient/mcu?type_visit=MCU&category_name=${body.category_name
      //     .toString()
      //     .replace("&", "%26")}&id=${this.register_id}`
      // );
      axiosIns
        .get(
          `${
            axiosIns.defaults.baseURL
          }/clinic/patient/mcu?type_visit=MCU&category_name=${body.category_name
            .toString()
            .replace("&", "%26")}&id=${body.id}`
        )
        .then((e) => {
          let gets = e.data.data.data[0].mcu[0].mcu_record[0];
          this.user_id = e.data.data.data[0].user_id;
          // this.user_id = gets.user_id
          this.status_kesehatanDef = gets.detail_record.filter(this.filtering3);
          this.bodyApproval.status_kesehatan =
            this.status_kesehatanDef[0].record[0].check_value;
        });
    },
    filtering3(e) {
      return e.group_name == "STATUS KESEHATAN";
    }
  }
};
</script>
